import { Component, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { applicantDetialsApi } from "@/providers/apis/applicant-dashboard";
import moment from "moment";
import { ReviewerCertificationApplicationInterface } from "@/models/reviewer-certification-applications/reviewer-certification-applications.interface";
import ReviewerCertificationPreview from "../reviewer-certification-preview/reviewer-certification-preview.component.vue";
import ReviewerInactivePreview from "../reviewer-inactive-preview/reviewer-inactive-preview.component.vue";
import ReviewerRenewalPreview from "../reviewer-renewal-preview/reviewer-renewal-preview.component.vue";
import { utils } from "@/utils/utils";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";

@Component({
  name: "user-applications",
  components: {
    AppLoaderComponent,
    ReviewerCertificationPreview,
    ReviewerInactivePreview,
    ReviewerRenewalPreview,
    ErrorPopupComponent,
  },
})
export default class UserApplicationsComponent extends Vue {
  public viewApplication = false;
  public toogleExpand = false;
  public isLoading = true;
  public activePreviewFormName = "";
  public applicationFieldDetials = {};
  public applicationListData: any = {};
  public certificatePreviewForm: ReviewerCertificationApplicationInterface =
    {} as ReviewerCertificationApplicationInterface;
  public label: any = {
    partTwo: "Initial Application",
    renewal: "Renewal Application",
    inActive: "Inactive Application",
  };
  public headrTitle: any = {
    partOne: "",
    partTwo: "Initial Application",
    renewal: "Renewal Application",
    stateToState: "State to State Application",
    inActive: "Inactive Application",
  };
  public errorMessage = "";
  public softRefresh = false;
  public updateReviewer: any;

  public created() {
    this.sldebarHide();
  }
  public sldebarHide() {
    this.isLoading = true;
    this.getUserApplication();
  }
  public async getUserApplication() {
    await applicantDetialsApi
      .getUserApplication()
      .then(async (result) => {
        const finalData: any = {};
        for (const property in result.data) {
          if (result.data[property]) {
            result.data[property].map(() => {
              finalData[property] = result.data[property].sort(
                (a: any, b: any) => {
                  a.createdAt = moment(a.createdAt).format(
                    "MM-DD-YYYY HH:mm:ss:SSS"
                  );
                  b.createdAt = moment(b.createdAt).format(
                    "MM-DD-YYYY HH:mm:ss:SSS"
                  );
                  return (
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                  );
                }
              );
              finalData[property].reverse();
              finalData[property]?.map((item: any) => {
                item.createdAt = moment(item.createdAt).format("MM-DD-YYYY");
              });
            });
          }
        }
        this.applicationListData = finalData;
        this.isLoading = false;
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }
  public viewApplicationDetials(formName: any, data: any) {
    this.softRefresh = !this.softRefresh;
    this.activePreviewFormName = formName;
    this.viewApplication = true;
    this.certificatePreviewForm = data;
  }

  public closeSidebar() {
    this.viewApplication = false;
  }

  public closePopup() {
    this.$bvModal.hide("error-modal-center");
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
  public onDraft(key: any, value: any, index: number) {
    if (value && value[index].fillStatus === "DRAFT") {
      value[index].data.fillStatus = value[index].fillStatus;
      value[index].data.id = value[index].id;
      this.onOpenDraftForm(key, value[index].id, value[index]);
    }
  }

  public onOpenDraftForm(key: any, applicantId: any, value: any) {
    if (key === "partTwo") {
      const formIndex = [];
      if (
        !Object.values(value.data.certificationPartTwoApplicationForm).every(
          (x) => x === null || x === ""
        )
      ) {
        formIndex.push("0");
      }

      if (
        !Object.values(
          value.data.certificationStatementOfPersonalExperience
        ).every((x) => x === null || x === "")
      ) {
        formIndex.push("1");
      }
      if (
        !Object.values(value.data.certificationApplicationDocument).every(
          (x) => x === null || x === ""
        )
      ) {
        formIndex.push("2");
      }
      if (
        !Object.values(
          value.data.certificationPartTwoRefererenceInformation
        ).every((x) => x === null || x === "")
      ) {
        formIndex.push("3");
      }
      if (
        !Object.values(
          value.data.certificationPartTwoSupervisorInformation
        ).every((x) => x === null || x === "")
      ) {
        formIndex.push("4");
      }
      if (
        !Object.values(
          value.data.certificationApplicationScopeOfActivities
        ).every((x) => x === null || x === "")
      ) {
        formIndex.push("5");
      }
      if (value.data.certificationSignature.isSignature) {
        formIndex.push("6");
      }
      localStorage.setItem(
        "certificationFormFilledIndex",
        formIndex.toString()
      );
      localStorage.setItem(
        "certificationFormPayload",
        JSON.stringify(value.data)
      );
      window.location.href = `/form/initial-form?id=${applicantId}&isDraft=true`;
    }
    if (key === "renewal") {
      const formIndex = [];
      if (
        !Object.values(value.data.renewalApplicationInformation).every(
          (x) => x === null || x === ""
        )
      ) {
        formIndex.push("0");
      }
      if (
        !Object.values(value.data.renewalTrainingInformation).every(
          (x) => x === null || x === ""
        )
      ) {
        formIndex.push("1");
      }
      if (
        !Object.values(value.data.renewalSupervisorInformation).every(
          (x) => x === null || x === ""
        )
      ) {
        formIndex.push("2");
      }
      if (value.data.renewalSignatureSubmission.isSignature) {
        formIndex.push("3");
      }
      localStorage.setItem("renewalPayLoadData", JSON.stringify(value.data));
      localStorage.setItem("renewalFormFilledIndex", formIndex.toString());
      window.location.href = `/form/renewal-form?id=${applicantId}&isDraft=true`;
    }
  }
  public getPeerUpdatedData(event: boolean) {
    this.getUserApplication();
    this.viewApplication = false;
    if (event) {
      this.activePreviewFormName = "";
      (this.$refs.mySidebar as HTMLFormElement).hide();
    }
  }
  public isUpdateForm(event: any) {
    this.updateReviewer = event;
    this.$nextTick(() => {
      this.$bvModal.show("update-email-center");
    });
  }
  public closeEmailPopup() {
    this.$bvModal.hide("update-email-center");
  }
}
