import { Component, Vue, Watch } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { RENEWAL_APPLICATION_FORM_QUERY } from "@/graphql/renewal-application-form-query";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import SingleCheckboxButton from "../single-checkbox-button/single-checkbox-button.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import {
  FormFieldContents,
  FormNavbar,
  RenewalApplicationsForm,
} from "@/models/renewal-form/renewal-form.interface";
import { renewalFormApi } from "@/providers/apis/renewal-application";
import { utils } from "@/utils/utils";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import RenewalPreviewFormComponent from "../renewal-preview-form/renewal-preview-form.component.vue";
import moment from "moment";
import { certificationFormApi } from "@/providers/apis/certification-form";
import { addSupervisor } from "@/models/certification-form/certification-form.interface";
import { docLibraryData } from "@/models/common-interface/common.interface";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";
import { applicantDetialsApi } from "@/providers/apis/applicant-dashboard";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import { RenewalRemainders } from "@/models/renewal-remainder/renewal-remainder-interface";
import { RENEWAL_REMAINDER } from "@/graphql/renewal-remainder-query";

@Component({
  name: "renewal-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-upload-file": FileUploadComponent,
    "b-form-single-checkbox": SingleCheckboxButton,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-renewal-preview-form": RenewalPreviewFormComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    ErrorPopupComponent,
    PreviewPopupComponent,
  },
})
export default class RenewalApplicationFormComponent extends Vue {
  public applicationData: Array<RenewalApplicationsForm> = [];
  public formData: Array<FormFieldContents> = [];
  public applicationNavData: Array<FormNavbar> = [];
  public supervisorFormFields: addSupervisor[] = [];
  public tempSupervisorFormFields: addSupervisor = {} as addSupervisor;
  public getAllDoc: docLibraryData[] = [];
  public formPagination = 0;
  public isNavLoad = false;
  public isScroll = false;
  public isValid = false;
  public showPreviewPopup = false;
  public supervisorUIUpdate = false;
  public addTrainingForm: any[] = [];
  public notFilesUploadedKeyNames: string[] = [];
  public supervisorEndDateShowIdx: number[] = [];
  public supervisorStartDateShowIdx: number[] = [];
  public supervisorEmailDuplicateIndexs: number[] = [];
  public trainingDuplicateIndexs: number[] = [];
  public previewFile: any[] = [];
  public renewalLocationId: any;
  public currentForm: any;
  public fileData: any;
  public saveDataDraft = false;
  public formName = "renewalApplicationInformation";
  public formTitle = {
    programTitle: "",
    formTitle: "",
  };
  public selected: any[] = []; // Must be an array reference!
  public options: any = [{ text: "Lorem Ipsum Training 1", value: "Training" }];
  public fileName = "";
  public renewalPayLoadData: any = {
    fillStatus: "DRAFT",
    renewalApplicationInformation: {
      firstName: "",
      lastName: "",
      certificationNumber: "",
      certificateExpirationDate: "",
      phone: "",
      email: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      zip: "",
      recognizedEducation: "",
      committedViolations: "",
      violationTCFSS: "",
      statements: "",
    },
    renewalTrainingInformation: [],
    renewalSupervisorInformation: [
      {
        supervisorFirstName: "",
        supervisorLastName: "",
        peerSupportAgencyOrganization: "",
        peerSupportYourPosition: "",
        currentlyEmployed: "",
        startDate: "",
        endDate: "",
        supervisorCredentials: "",
        phone: "",
        supervisorEmail: "",
        descripePeerSupportResponsibity: "",
      },
    ],
    renewalSignatureSubmission: {
      isSignature: "",
      submissionDate: moment().format("MM-DD-YYYY"),
    },
  };
  public isTrainingReferesh = "false";
  public isTrainingSaved = true;
  public notSavedTrainingIndex: number[] = [];
  public userDetails: any;
  public isReviewerUser: any;
  public applicantFullDetials: any;
  public formCompletedIndex: number[] = [];
  public signatureInvalid = false;
  public checkValidForm = false;
  public mainId = "app";
  public currentRoute = "";
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public isFileValid = false;
  public isLoading = true;
  public isEdit = false;
  public errorMessage = "";
  public isNavigatefromUserDashboard = false;
  public disableFiled = ["certificationNumber", "certificateExpirationDate"];
  public checkField: string[] = [
    "currentlyEmployed",
    "recognizedEducation",
    "committedViolations",
    "violationTCFSS",
  ];
  public hideDiv: string[] = ["statements"];
  payLoadData: any;
  addAnother: ((payload: MouseEvent) => void) | undefined;
  public educationLists: any;
  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public S3Path = "";
  public seletedTraning: any[] = [];
  public trainingIndex = 2;
  public isAvailableTraining = false;
  public availableTraining: any[] = [];
  public renewalRemainder: RenewalRemainders = {} as RenewalRemainders;

  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${RENEWAL_REMAINDER}`,
      })
      .then((result) => {
        const remainders: any[] = result.data.renewalReminders.data;
        if (remainders.length) {
          const remaindersData = remainders.find(
            (data) => data.attributes.reminder_seq === 1
          );
          this.renewalRemainder = remaindersData.attributes;
        }
      });
    await utils.getAllDoc().then((data) => {
      this.getAllDoc = data;
    });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
    this.currentRoute = this.$route.fullPath;
    const userDetails = localStorage.getItem("user_details");
    const token = localStorage.getItem("access_token");
    if (userDetails) {
      this.userDetails = JSON.parse(userDetails);
      const userRolesData = this.userDetails.roles;
      this.isReviewerUser =
        userRolesData && userRolesData.indexOf("REVIEWER") !== -1;
    } else {
      this.isReviewerUser = false;
    }
    let payloadStorage: any = localStorage.getItem("renewalPayLoadData");
    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.renewalPayLoadData = { ...payloadStorage };
    }
    if (this.$route.query.id) {
      this.isNavigatefromUserDashboard = true;
      const applicantId: string = this.$route.query.id as string;
      if (
        !this.isReviewerUser &&
        token &&
        (this.userDetails.roles === null || !this.userDetails.roles)
      ) {
        if (this.$route.query.isDraft) {
          renewalFormApi
            .getRenewalnApplicationForm(applicantId)
            .then((result: any) => {
              this.storeBasicDetais(result.data);
            });
        } else {
          await certificationFormApi
            .getCertificationApplicationForm(applicantId)
            .then((result: any) => {
              this.storeBasicDetais(result.data);
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
              this.$bvModal.show("error-modal-center");
              setTimeout(() => {
                this.$bvModal.hide("error-modal-center");
              }, 3000);
            });
        }
      } else {
        if (!payloadStorage) {
          this.$router.push({
            name: "login-page",
            query: { application: "renewal-form" },
          });
        }
      }
    } else {
      if (!this.isReviewerUser && token && !payloadStorage) {
        this.isNavLoad = false;
        this.renewalPayLoadData.renewalApplicationInformation.firstName =
          this.userDetails.first_name;
        this.renewalPayLoadData.renewalApplicationInformation.lastName =
          this.userDetails.last_name;
        this.renewalPayLoadData.renewalApplicationInformation.email =
          this.userDetails.email;
        this.renewalPayLoadData.renewalApplicationInformation.phone =
          this.userDetails.phone_number;

        this.renewalPayLoadData.renewalApplicationInformation.addressLine1 =
          this.userDetails.address_line_1;
        this.renewalPayLoadData.renewalApplicationInformation.addressLine2 =
          this.userDetails.address_line_2;
        this.renewalPayLoadData.renewalApplicationInformation.state =
          this.userDetails.state;
        this.renewalPayLoadData.renewalApplicationInformation.city =
          this.userDetails.city;
        this.renewalPayLoadData.renewalApplicationInformation.zip =
          this.userDetails.zip;
      } else {
        if (!payloadStorage) {
          this.$router.push({
            name: "login-page",
            query: { application: "renewal-form" },
          });
        }
      }
    }
    const formFilledIndex: any = localStorage.getItem("renewalFormFilledIndex");
    if (formFilledIndex) {
      formFilledIndex.split(",").forEach((data: string) => {
        this.formCompletedIndex.push(Number(data));
      });
    } else {
      if (!this.formCompletedIndex.includes(this.formPagination)) {
        this.formCompletedIndex.push(this.formPagination);
      }
    }
    if (this.formPagination === 0) {
      // this.isNavLoad = true;
    }
    this.$apollo
      .query({
        query: gql`query ${RENEWAL_APPLICATION_FORM_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.renewalApplicationForms.data;
        this.formTitle = {
          programTitle: this.applicationData[0].attributes.programTitle,
          formTitle: this.applicationData[0].attributes.formTitle,
        };
        this.applicationNavData =
          this.applicationData[0].attributes.renewalNavbar;
        this.formData = this.applicationData[0].attributes.form_fields;
        this.tempSupervisorFormFields = JSON.parse(
          JSON.stringify(
            this.applicationData[0].attributes.form_fields[2].fields
              .multiForm[0]
          )
        );
        if (this.renewalPayLoadData.renewalSupervisorInformation.length) {
          const addSupervisorForm =
            this.renewalPayLoadData.renewalSupervisorInformation.length;
          for (let index = 0; index < addSupervisorForm; index++) {
            this.supervisorFormFields.push(this.tempSupervisorFormFields);
          }
        }
        for (let index = 0; index < this.checkField.length; index++) {
          for (
            let i = 0;
            i < this.renewalPayLoadData.renewalSupervisorInformation.length;
            i++
          ) {
            this.fieldUpdated(this.checkField[index], i);
          }
        }
        this.isLoading = false;
      });
    this.getTraningDetail();
  }

  public mounted() {
    const mainEl: any = document.querySelector(".main-content");
    if (mainEl) {
      mainEl.addEventListener("keyup", (event: any) => {
        if (event.shiftKey && event.keyCode == 9) {
          const el = event.path[0];
          if (el) {
            el.scrollIntoView({ block: "center", behavior: "smooth" });
          }
        }
      });
    }
  }

  public storeBasicDetais(basicdetails: any) {
    this.renewalPayLoadData.renewalApplicationInformation.certificationNumber =
      basicdetails.certificateNumber;
    this.renewalPayLoadData.renewalApplicationInformation.certificateExpirationDate =
      basicdetails.expiryDate;
    this.renewalPayLoadData.renewalApplicationInformation.firstName =
      basicdetails.firstName;
    this.renewalPayLoadData.renewalApplicationInformation.lastName =
      basicdetails.lastName;
    this.renewalPayLoadData.renewalApplicationInformation.phone =
      basicdetails.data.certificationPartTwoApplicationForm.phone;
    this.renewalPayLoadData.renewalApplicationInformation.email =
      basicdetails.data.certificationPartTwoApplicationForm.email;
    this.renewalPayLoadData.renewalApplicationInformation.addressLine1 =
      basicdetails.data.certificationPartTwoApplicationForm.addressLine1;
    this.renewalPayLoadData.renewalApplicationInformation.addressLine2 =
      basicdetails.data.certificationPartTwoApplicationForm.addressLine2;
    this.renewalPayLoadData.renewalApplicationInformation.state =
      basicdetails.data.certificationPartTwoApplicationForm.state;
    this.renewalPayLoadData.renewalApplicationInformation.city =
      basicdetails.data.certificationPartTwoApplicationForm.city;
    this.renewalPayLoadData.renewalApplicationInformation.zip =
      basicdetails.data.certificationPartTwoApplicationForm.zip;
  }

  public fileSave(fileData: any, index: number) {
    this.isFileValid = !this.isFileValid;
    this.$validator.validateAll().then(async (validate) => {
      if (validate && !this.notFilesUploadedKeyNames.length) {
        this.isTrainingReferesh = this.changeStateForTraining(
          this.isTrainingReferesh
        );
        const findIndex = this.notSavedTrainingIndex.indexOf(index);
        this.notSavedTrainingIndex.splice(findIndex, 1);
        localStorage.setItem(
          "renewalPayLoadData",
          JSON.stringify(this.renewalPayLoadData)
        );
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
      }
    });
  }

  public nextButton() {
    this.checkValidForm = true;
    this.isFileValid = !this.isFileValid;

    if (this.formPagination === 1) {
      this.trainingDuplicateIndexs = utils.duplicateValueIdx(
        this.renewalPayLoadData,
        "renewalTrainingInformation",
        false,
        "trainingName"
      );
      if (this.trainingDuplicateIndexs.length) {
        this.formPagination = 1;
        this.formName = "renewalTrainingInformation";
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 50);
        return;
      }
    }

    if (this.formPagination === 2) {
      this.supervisorEmailDuplicateIndexs = utils.duplicateValueIdx(
        this.renewalPayLoadData,
        this.formName,
        true,
        "supervisorEmail"
      );
      if (this.supervisorEmailDuplicateIndexs.length) {
        this.formPagination = 2;
        this.formName = "renewalSupervisorInformation";
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 50);
        return;
      }

      const supervisorEmailValidate =
        this.renewalPayLoadData.renewalSupervisorInformation.filter(
          (supervisor: any) => {
            if (
              supervisor.supervisorEmail.toLowerCase() ===
              this.renewalPayLoadData.renewalApplicationInformation.email.toLowerCase()
            ) {
              return supervisor;
            }
          }
        );

      if (supervisorEmailValidate.length) {
        this.errorMessage =
          "Supervisor email id and applicant email id must be different *";
        return;
      }
    }

    if (this.formPagination == 3) {
      if (this.renewalPayLoadData.renewalSignatureSubmission.isSignature) {
        this.signatureInvalid = false;
      } else {
        this.checkValidForm = true;
        this.signatureInvalid = true;
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
        return;
      }
    }

    this.$validator.validateAll().then(async (validate) => {
      if (
        !this.notSavedTrainingIndex.length &&
        validate &&
        !this.notFilesUploadedKeyNames.length
      ) {
        // Find Days count between Expiry Date and Current Date
        const startDate = moment(moment(new Date()).format("MM-DD-YYYY"));
        const endDate = moment(
          moment(
            this.renewalPayLoadData.renewalApplicationInformation
              .certificateExpirationDate
          ).format("MM-DD-YYYY")
        );
        const daysCount = Math.floor(endDate.diff(startDate, "days"));
        if (daysCount > this.renewalRemainder.days) {
          this.errorMessage = `You can't fill renewal application now for this certificate. Please try before ${this.renewalRemainder.days} days to expire.`;
          this.formPagination = 0;
          this.formName = "renewalApplicationInformation";
          return;
        }
        this.isLoading = true;
        this.checkValidForm = false;
        this.isValid = true;
        if (
          this.formName === "renewalTrainingInformation" &&
          this.educationLists &&
          this.educationLists.length
        ) {
          this.renewalPayLoadData.renewalTrainingInformation =
            this.renewalPayLoadData.renewalTrainingInformation.filter(
              (data: any) => {
                return data.isNew;
              }
            );
          this.educationLists.filter((traning: any) => {
            if (traning.isChecked) {
              const data = {
                trainingName: traning.title,
                proofOfAttendance: traning.attachments,
                trainingDuration: traning?.trainingHours
                  ? traning.trainingHours
                  : "",
                trainingFormat:
                  traning && traning.trainingFormat
                    ? traning.trainingFormat === "virtual"
                      ? false
                      : true
                    : "",
                isNew: false,
                isChecked: traning.isChecked,
              };
              this.renewalPayLoadData.renewalTrainingInformation.push(data);
            }
          });
        }
        if (this.renewalPayLoadData.id == null) {
          await renewalFormApi
            .RenewalApplicationForm(
              this.renewalPayLoadData.fillStatus,
              this.renewalPayLoadData
            )
            .then((result: any) => {
              this.isLoading = false;
              this.renewalLocationId = result.headers.location;
              localStorage.setItem(
                "renewalApplicationId",
                this.renewalLocationId
              );
              this.renewalPayLoadData.id = localStorage.getItem(
                "renewalApplicationId"
              );
              localStorage.setItem(
                "renewalPayLoadData",
                JSON.stringify(this.renewalPayLoadData)
              );
              if (!this.isEdit) {
                this.nextPaginationUpdate();
              }
              const el = document.getElementById(this.mainId);
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
              this.$bvModal.show("error-modal-center");
              setTimeout(() => {
                this.$bvModal.hide("error-modal-center");
              }, 4000);
            });
        } else {
          const id = this.renewalPayLoadData.id;
          await renewalFormApi
            .updateRenewalForm(this.renewalPayLoadData, id)
            .then((data) => {
              this.isLoading = false;
              if (!this.isEdit) {
                this.nextPaginationUpdate();
              }
              const el = document.getElementById(this.mainId);
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
              this.$bvModal.show("error-modal-center");
              setTimeout(() => {
                this.$bvModal.hide("error-modal-center");
              }, 3000);
            });
          localStorage.setItem(
            "renewalPayLoadData",
            JSON.stringify(this.renewalPayLoadData)
          );
          if (this.isEdit) {
            this.formPagination = 4;
            this.formName = "renewalApplicationInformation";
          }
        }
        if (!this.formCompletedIndex.includes(this.formPagination)) {
          this.formCompletedIndex.push(this.formPagination);
        }
        this.currentForm = this.renewalPayLoadData[this.formName];
        localStorage.setItem(
          "renewalFormFilledIndex",
          this.formCompletedIndex.toString()
        );
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
      }
    });
  }
  public resetFileKey(event: boolean) {
    if (event) {
      this.notFilesUploadedKeyNames = [];
    }
  }
  public nextPaginationUpdate() {
    if (this.formPagination < this.formData.length - 1) {
      this.isNavLoad = true;
      const page = this.formPagination + 1;
      if (page == 0) {
        this.formName = "renewalApplicationInformation";
      } else if (page == 1) {
        this.formName = "renewalTrainingInformation";
        const renewalFormData =
          this.renewalPayLoadData.renewalTrainingInformation;
        if (renewalFormData.length) {
          this.showTrainingForm();
        }
        this.setAvailableTraining();
      } else if (page == 2) {
        this.formName = "renewalSupervisorInformation";
      } else if (page == 3) {
        this.formName = "renewalSignatureSubmission";
      }
      this.formPagination = page;
    }
  }

  public previousButton() {
    this.notFilesUploadedKeyNames = [];
    this.isEdit = false;
    if (this.formPagination !== 0) {
      const el = document.getElementById(this.mainId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      }
      this.formPagination = this.formPagination - 1;
      if (this.formPagination == 0) {
        this.formName = "renewalApplicationInformation";
      } else if (this.formPagination == 1) {
        this.formName = "renewalTrainingInformation";
        const renewalFormData =
          this.renewalPayLoadData.renewalTrainingInformation;
        if (renewalFormData.length) {
          this.showTrainingForm();
        }
        this.setAvailableTraining();
      } else if (this.formPagination == 2) {
        this.formName = "renewalSupervisorInformation";
      } else if (this.formPagination == 3) {
        this.formName = "renewalSignatureSubmission";
      }
    }
  }
  public onSubmit() {
    this.$validator.validate().then((validate) => {
      if (validate) {
        this.isLoading = true;
        let payloadStorage: any = localStorage.getItem("renewalPayLoadData");
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.renewalPayLoadData = { ...payloadStorage };
        }
        this.renewalPayLoadData.fillStatus = "COMPLETED";
        this.renewalPayLoadData.id = localStorage.getItem(
          "renewalApplicationId"
        );
        const id = this.renewalPayLoadData.id;
        renewalFormApi
          .updateRenewalForm(this.renewalPayLoadData, id)
          .then(() => {
            const someData = {
              title: "Certified Family Support Specialist Program",
              SubTitle: "Renewal Application",
            };
            this.$router.push({
              path: "/form/submitted",
              query: { subTitle: someData.SubTitle },
            });
            this.isLoading = false;
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
            localStorage.removeItem("renewalFormFilledIndex");
            localStorage.removeItem("renewalPayLoadData");
            localStorage.removeItem("renewalApplicationId");
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
            this.$bvModal.show("error-modal-center");
            setTimeout(() => {
              this.$bvModal.hide("error-modal-center");
            }, 3000);
          });
      }
    });
  }

  public saveDraft() {
    this.$bvToast.show("example-toast");
    this.isValid = true;
    localStorage.setItem(
      "renewalPayLoadData",
      JSON.stringify(this.renewalPayLoadData)
    );
    this.saveDataDraft = true;
    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  public showTrainingForm() {
    const renewalFormData = this.renewalPayLoadData.renewalTrainingInformation;
    if (renewalFormData.length) {
      this.addTrainingForm = [];
      this.renewalPayLoadData.renewalTrainingInformation =
        renewalFormData.filter((data: any) => {
          if (this.educationLists && this.educationLists.length) {
            this.educationLists.filter((education: any) => {
              if (
                data.id === education.id ||
                data.trainingName === education.trainingName
              ) {
                education.isChecked = true;
              }
            });
          }
          return data.isNew;
        });
      this.renewalPayLoadData.renewalTrainingInformation.forEach(() => {
        this.addTrainingForm.push(this.formData[1].fields.formInputs);
      });
    }
  }

  public formEdit(slug: any) {
    this.isEdit = true;
    this.formName = slug;
    if (slug == "renewalApplicationInformation") {
      this.formPagination = 0;
    } else if (slug == "renewalTrainingInformation") {
      const renewalFormData =
        this.renewalPayLoadData.renewalTrainingInformation;
      if (renewalFormData.length) {
        this.showTrainingForm();
      }
      this.formPagination = 1;
    } else if (slug == "renewalSupervisorInformation") {
      this.formPagination = 2;
    } else if (slug == "renewalSignatureSubmission") {
      this.renewalPayLoadData.renewalSignatureSubmission.isSignature = "";
      localStorage.setItem(
        "renewalPayLoadData",
        JSON.stringify(this.renewalPayLoadData)
      );
      this.formPagination = 3;
    }
    const el = document.getElementById(this.mainId);
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }

  public pushTrainingForm() {
    this.addTrainingForm.push(this.formData[1].fields.formInputs);
    this.renewalPayLoadData.renewalTrainingInformation =
      this.renewalPayLoadData.renewalTrainingInformation.filter((data: any) => {
        return data.isNew;
      });
    this.renewalPayLoadData.renewalTrainingInformation.push({
      trainingName: "",
      trainingDuration: "",
      trainingFormat: "",
      isNew: true,
      proofOfAttendance: [],
    });
    localStorage.setItem(
      "renewalPayLoadData",
      JSON.stringify(this.renewalPayLoadData)
    );
  }

  public addTraining() {
    this.isFileValid = !this.isFileValid;
    const renewalFormData = this.renewalPayLoadData.renewalTrainingInformation;
    if (renewalFormData.length && !this.isAvailableTraining) {
      this.$validator.validateAll().then((validate) => {
        if (validate && !this.notFilesUploadedKeyNames.length) {
          if (this.addTrainingForm.length) {
            this.pushTrainingForm();
          }
        } else {
          setTimeout(() => {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }, 10);
        }
      });
    } else {
      this.pushTrainingForm();
    }
  }

  public removeTraining(index: number) {
    this.notFilesUploadedKeyNames = [];
    this.isTrainingReferesh = this.changeStateForTraining(
      this.isTrainingReferesh
    );
    this.renewalPayLoadData.renewalTrainingInformation.splice(index, 1);
    this.addTrainingForm.splice(index, 1);
    this.showTrainingForm();
    this.setAvailableTraining();
    localStorage.setItem(
      "renewalPayLoadData",
      JSON.stringify(this.renewalPayLoadData)
    );
    setTimeout(() => {
      this.isTrainingReferesh = this.changeStateForTraining(
        this.isTrainingReferesh
      );
    }, 10);
  }

  public changeStateForTraining(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any) {
    utils.onlyAlphabets(evt);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public emptyEvent() {
    return;
  }

  public async navigateTab(slug: string, index: number) {
    if (this.formCompletedIndex.includes(index)) {
      this.isEdit = false;
      if (index > this.formPagination) {
        this.isFileValid = !this.isFileValid;
        this.checkValidForm = true;

        if (index > 1) {
          this.trainingDuplicateIndexs = utils.duplicateValueIdx(
            this.renewalPayLoadData,
            "renewalTrainingInformation",
            false,
            "trainingName"
          );
          if (this.trainingDuplicateIndexs.length) {
            this.formPagination = 1;
            this.formName = "renewalTrainingInformation";
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 50);
            return;
          }
        }

        if (index > 2) {
          this.supervisorEmailDuplicateIndexs = utils.duplicateValueIdx(
            this.renewalPayLoadData,
            "renewalSupervisorInformation",
            true,
            "supervisorEmail"
          );
          if (this.supervisorEmailDuplicateIndexs.length) {
            this.formPagination = 2;
            this.formName = "renewalSupervisorInformation";
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 50);
            return;
          }

          const supervisorEmailValidate =
            this.renewalPayLoadData.renewalSupervisorInformation.filter(
              (supervisor: any) => {
                if (
                  supervisor.supervisorEmail.toLowerCase() ===
                  this.renewalPayLoadData.renewalApplicationInformation.email.toLowerCase()
                ) {
                  return supervisor;
                }
              }
            );
          if (supervisorEmailValidate.length) {
            this.formPagination = 2;
            this.formName = "renewalSupervisorInformation";
            this.errorMessage =
              "Supervisor email id and applicant email id must be different *";
            return;
          }
        }

        //signature validation
        if (this.formPagination == 3) {
          if (this.renewalPayLoadData.renewalSignatureSubmission.isSignature) {
            this.signatureInvalid = false;
          } else {
            this.signatureInvalid = true;
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 10);
            return;
          }
        }

        this.$validator.validateAll().then(async (validate) => {
          if (
            !this.notSavedTrainingIndex.length &&
            validate &&
            !this.notFilesUploadedKeyNames.length
          ) {
            if (index > 0) {
              // Find Days count between Expiry Date and Current Date
              const startDate = moment(moment(new Date()).format("MM-DD-YYYY"));
              const endDate = moment(
                moment(
                  this.renewalPayLoadData.renewalApplicationInformation
                    .certificateExpirationDate
                ).format("MM-DD-YYYY")
              );
              const daysCount = Math.floor(endDate.diff(startDate, "days"));
              if (daysCount > this.renewalRemainder.days) {
                this.errorMessage = `You can't fill renewal application now for this certificate. Please try before  ${this.renewalRemainder.days} days to expire.`;
                this.formPagination = 0;
                this.formName = "renewalApplicationInformation";
                return;
              }
            }
            this.checkValidForm = false;
            this.isValid = true;
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            localStorage.setItem(
              "renewalPayLoadData",
              JSON.stringify(this.renewalPayLoadData)
            );
            if (index === 4) {
              this.formPagination = index;
              this.formName = "renewalApplicationInformation";
            } else {
              this.formName = slug;
              this.formPagination = index;
              if (this.formName === "renewalTrainingInformation") {
                const renewalFormData =
                  this.renewalPayLoadData.renewalTrainingInformation;
                if (renewalFormData.length) {
                  this.showTrainingForm();
                  this.setAvailableTraining();
                }
              }
            }
          } else {
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 10);
          }
        });
      } else {
        this.notFilesUploadedKeyNames = [];
        if (index === 4) {
          this.formPagination = index;
          this.formName = "renewalApplicationInformation";
        } else {
          this.formName = slug;
          this.formPagination = index;
          if (this.formName === "renewalTrainingInformation") {
            const renewalFormData =
              this.renewalPayLoadData.renewalTrainingInformation;
            if (renewalFormData.length) {
              this.showTrainingForm();
              this.setAvailableTraining();
            }
          }
        }
        const el = document.getElementById(this.mainId);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }

  public closePopup() {
    this.$bvModal.hide("error-modal-center");
  }

  public allFilesUploaded(event: any) {
    if (this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.splice(event, 1);
    }
  }

  public notFileUploaded(event: string) {
    if (!this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.push(event);
    }
  }

  // Supervisor flow

  public addAnotherSupervisor() {
    this.renewalPayLoadData.renewalSupervisorInformation.push({
      supervisorFirstName: "",
      supervisorLastName: "",
      peerSupportAgencyOrganization: "",
      peerSupportYourPosition: "",
      currentlyEmployed: "",
      startDate: "",
      endDate: "",
      supervisorCredentials: "",
      phone: "",
      supervisorEmail: "",
      descripePeerSupportResponsibity: "",
    });
    this.supervisorFormFields.push(this.tempSupervisorFormFields);
  }

  public fieldUpdated(groupName = "", supervisorIdx?: number) {
    switch (groupName) {
      case "currentlyEmployed":
        if (supervisorIdx !== undefined) {
          if (
            this.renewalPayLoadData.renewalSupervisorInformation[supervisorIdx]
              .currentlyEmployed === "Yes"
          ) {
            if (this.supervisorEndDateShowIdx.indexOf(supervisorIdx) >= 0) {
              this.supervisorEndDateShowIdx.splice(
                this.supervisorEndDateShowIdx.indexOf(supervisorIdx),
                1
              );
              this.renewalPayLoadData.renewalSupervisorInformation[
                supervisorIdx
              ].endDate = "";
            }
            if (this.supervisorStartDateShowIdx.length) {
              if (!this.supervisorStartDateShowIdx.includes(supervisorIdx)) {
                this.supervisorStartDateShowIdx.push(supervisorIdx);
              }
            } else {
              this.supervisorStartDateShowIdx = [supervisorIdx];
            }
            this.supervisorUIUpdate = utils.UIUpdateUsingKey(
              this.supervisorUIUpdate
            );
          } else if (
            this.renewalPayLoadData.renewalSupervisorInformation[supervisorIdx]
              .currentlyEmployed === "No"
          ) {
            if (!this.supervisorStartDateShowIdx.includes(supervisorIdx)) {
              this.supervisorStartDateShowIdx.push(supervisorIdx);
            }
            if (this.supervisorEndDateShowIdx.length) {
              if (!this.supervisorEndDateShowIdx.includes(supervisorIdx)) {
                this.supervisorEndDateShowIdx.push(supervisorIdx);
              }
            } else {
              this.supervisorEndDateShowIdx = [supervisorIdx];
            }
            this.supervisorUIUpdate = utils.UIUpdateUsingKey(
              this.supervisorUIUpdate
            );
          }
        }
        break;
      case "recognizedEducation":
        if (
          this.renewalPayLoadData.renewalApplicationInformation
            .committedViolations === "No" ||
          this.renewalPayLoadData.renewalApplicationInformation
            .violationTCFSS === "No" ||
          this.renewalPayLoadData.renewalApplicationInformation
            .recognizedEducation === "No"
        ) {
          this.hideDiv.splice(this.hideDiv.indexOf("statements"), 1);
        } else {
          if (!this.hideDiv.includes("statements")) {
            this.renewalPayLoadData.renewalApplicationInformation.statements =
              "";
            this.hideDiv.push("statements");
          } else {
            return;
          }
        }
        break;
      case "committedViolations":
        if (
          this.renewalPayLoadData.renewalApplicationInformation
            .committedViolations === "No" ||
          this.renewalPayLoadData.renewalApplicationInformation
            .violationTCFSS === "No" ||
          this.renewalPayLoadData.renewalApplicationInformation
            .recognizedEducation === "No"
        ) {
          this.hideDiv.splice(this.hideDiv.indexOf("statements"), 1);
        } else {
          if (!this.hideDiv.includes("statements")) {
            this.renewalPayLoadData.renewalApplicationInformation.statements =
              "";
            this.hideDiv.push("statements");
          } else {
            return;
          }
        }
        break;
      case "violationTCFSS":
        if (
          this.renewalPayLoadData.renewalApplicationInformation
            .committedViolations === "No" ||
          this.renewalPayLoadData.renewalApplicationInformation
            .violationTCFSS === "No" ||
          this.renewalPayLoadData.renewalApplicationInformation
            .recognizedEducation === "No"
        ) {
          this.hideDiv.splice(this.hideDiv.indexOf("statements"), 1);
        } else {
          if (!this.hideDiv.includes("statements")) {
            this.renewalPayLoadData.renewalApplicationInformation.statements =
              "";
            this.hideDiv.push("statements");
          } else {
            return;
          }
        }
        break;
    }
  }

  public updated() {
    window.document.onclick = (e) => {
      if (e.target !== null) {
        const getElement: HTMLElement = e.target as HTMLElement;
        const tagId = getElement.id;
        const getDocElement: HTMLAnchorElement = document.getElementById(
          tagId
        ) as HTMLAnchorElement;
        if (getDocElement) {
          const docDetials = utils.filterDoc(this.getAllDoc, tagId);
          if (docDetials) {
            this.showPreviewPopup = true;
            this.previewFile = [
              {
                fileName: "",
                width: 0,
                height: 0,
                type: docDetials.mime,
                originalFileName: docDetials.name,
                url: docDetials.url,
              },
            ];
          }
        }
      }
    };
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFile = [];
    }
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }

  public removeAddedSupervisor(indexForm: number) {
    this.renewalPayLoadData.renewalSupervisorInformation.splice(indexForm, 1);
    this.supervisorFormFields.splice(indexForm, 1);
    this.supervisorUIUpdate = utils.UIUpdateUsingKey(this.supervisorUIUpdate);
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }
  public goBack() {
    if (this.$route.query.isDraft) {
      localStorage.removeItem("renewalPayLoadData");
      localStorage.removeItem("renewalFormFilledIndex");
    }
    this.$router.push({
      path: `/home`,
    });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("active");
      el.classList.remove("remove-container");
    }
  }
  public getTraningDetail() {
    applicantDetialsApi
      .getContinuingEducation()
      .then((result) => {
        this.educationLists = result.data.educations;
        this.educationLists = this.educationLists.filter((item: any) => {
          item.trainingDate = moment(item.trainingDate).format("MM-DD-YYYY");
          item.trainingName = item.title;
          return item.isArchived === false;
        });
        this.isLoading = false;
        if (this.renewalPayLoadData) {
          this.renewalPayLoadData?.renewalTrainingInformation?.filter(
            (training: any) => {
              this.educationLists.filter((education: any) => {
                if (training.id === education.id) {
                  education.isChecked = true;
                }
              });
            }
          );
        }
        if (this.renewalPayLoadData.renewalTrainingInformation) {
          for (
            let i = 0;
            i < this.renewalPayLoadData.renewalTrainingInformation.length;
            i++
          ) {
            if (this.renewalPayLoadData.renewalTrainingInformation[i]) {
              this.renewalPayLoadData.renewalTrainingInformation[
                i
              ].trainingDuration = this.renewalPayLoadData
                .renewalTrainingInformation[i].trainingDuration
                ? this.renewalPayLoadData.renewalTrainingInformation[i]
                    .trainingDuration
                : 0;
            }
          }
        }
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }
  public checked(training: any) {
    this.isAvailableTraining = true;
    if (training.isChecked) {
      this.availableTraining.push(training);
    } else {
      this.availableTraining = this.availableTraining.filter(
        (item: any) => item.id !== training.id
      );
    }
  }
  public previewForm(rowData: any) {
    rowData.attachments.forEach((item: any) => {
      item.uploadedFileName = item.fileName;
      const temp = JSON.parse(JSON.stringify(item));
      const previewData = temp;
      if (this.imgType.includes(previewData.uploadedFileName.split(".")[1])) {
        const img = new Image();
        img.src = `${this.S3Path}/${previewData.uploadedFileName}`;
        previewData.width = img.width;
        previewData.height = img.height;
      }
      if (!this.previewFile.length) {
        this.previewFile = [previewData];
      } else {
        this.previewFile.push(previewData);
      }
      this.showPreviewPopup = true;
    });
  }
  public showMore(type: boolean) {
    this.isLoading = true;
    if (type) {
      this.trainingIndex = 2;
    } else {
      this.trainingIndex = this.trainingIndex + 3;
    }
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }
  public setAvailableTraining() {
    this.renewalPayLoadData.renewalTrainingInformation =
      this.renewalPayLoadData.renewalTrainingInformation.filter((data: any) => {
        return data.isNew;
      });
    if (this.educationLists.length) {
      this.educationLists.filter((traning: any) => {
        if (traning.isChecked) {
          const data = {
            trainingName: traning.title,
            proofOfAttendance: traning.attachments,
            trainingDuration: traning.trainingHours,
            trainingFormat:
              traning && traning.trainingFormat ? traning.trainingFormat : "",
            isNew: false,
            isChecked: traning.isChecked,
          };
          this.renewalPayLoadData.renewalTrainingInformation.push(data);
        }
      });
    }
  }
}
