import { BASIC_DETAIL_SUB_QUESTIONS_QUERY } from "@/graphql/experience-information-query";
import { SCOPE_OF_ACTIVITY_QUERY } from "@/graphql/scope-activity-and-code-ethics-query";
import { User } from "@/models/common-interface/common.interface";
import {
  FromFieldData,
  ScopeOfActivitieData,
} from "@/models/scope-of-activitie/scope-of-activitie.interface";
import { utils } from "@/utils/utils";
import gql from "graphql-tag";
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class EditHistoryComponent extends Vue {
  @Prop()
  public previewPayload: any;
  @Prop()
  public originalData: any;
  @Prop()
  public isClickedSave!: string;
  @Prop()
  public formData: any;
  @Prop()
  public tabSelect: any;
  @Prop()
  public removeMultiForm!: {
    index: number;
    formName: string;
    data: any;
    oldData: any;
  };
  public experienceData: any;
  public scopeOfActivityFormData: any;
  public editApplication = true;
  public isInvalidComment = false;
  public isEdited = false;
  public toogleExpand = false;
  public isFieldsReferesh = "false";
  public currentRoute = "";
  public editParentSlug = "";
  public changesCount = 0;
  public userDetails: User = {} as User;
  public editedFields: any[] = [];
  public editedFormNames: string[] = [];
  public editHistoryComments = {
    comment: "",
    dateTime: "",
    editorDetails: {
      name: "",
      email: "",
      userId: "",
    },
  };
  public filesEdit: string[] = [
    "primaryParentUpload",
    "demonstratedUpload",
    "copyOfEducations",
    "parentTraining",
    "competencesCourse",
  ];
  public SOALabels: any = {
    scopeOfActivityFullName: "Scope of Activity Full Name",
    codeOfEthicsFullName: "Code of Ethics Full Name",
    handBookFullName: "Handbook Full Name",
  };
  public getCurrentParentSlug: any = {
    "certification-basic-details": "certificationPartTwoApplicationForm",
    "personal-experience": "certificationStatementOfPersonalExperience",
    "application-document": "certificationApplicationDocument",
    "certification-supervisor-details":
      "certificationPartTwoSupervisorInformation",
    "certification-references": "certificationPartTwoRefererenceInformation",
    "scope-activities": "certificationApplicationScopeOfActivities",
    "certification-signature": "certificationSignature",
    "basic-details": "renewalApplicationInformation",
    "training-information": "renewalTrainingInformation",
    "supervisor-details": "renewalSupervisorInformation",
    "renewal-signature": "renewalSignatureSubmission",
  };
  @Prop()
  public editIndex: any;
  public isSidebarVisible = false;
  public async created() {
    this.userDetails = utils.getUserDetails();
    await this.$apollo
      .query({
        query: gql`query ${SCOPE_OF_ACTIVITY_QUERY}`,
      })
      .then((result) => {
        this.scopeOfActivityFormData = result.data.coeSoas.data;
      });
    await this.$apollo
      .query({
        query: gql`query ${BASIC_DETAIL_SUB_QUESTIONS_QUERY}`,
      })
      .then((result) => {
        this.experienceData = result.data.basicDetailsSubQuestions.data;
      });
    this.currentRoute = this.$route.path;
    this.showEditedData();
  }

  @Watch("isClickedSave")
  public showEditedData() {
    let temp: any;
    let label = "";
    let title = "";
    this.editApplication = true;
    let originalDataProperty;
    let previewPayloadProperty;
    this.isSidebarVisible = true;
    if (this.editApplication && this.editedFields.length) {
      document.body.classList.add("hide-scroll");
    }
    for (const property in this.originalData) {
      if (property !== "History") {
        if (property === "renewalTrainingInformation") {
          this.multiFormEditHistory(property);
        } else if (this.originalData[property]["handbook"]) {
          this.scopeOfActivities(property);
        } else if (this.originalData[property]["experienceInformation"]) {
          this.basicExperienceInformation(property);
        }
        if (
          property === "certificationPartTwoSupervisorInformation" ||
          property === "certificationPartTwoRefererenceInformation" ||
          property === "renewalSupervisorInformation"
        ) {
          originalDataProperty = this.originalData[property][this.editIndex];
          previewPayloadProperty =
            this.previewPayload[property][this.editIndex];
        } else {
          originalDataProperty = this.originalData[property];
          previewPayloadProperty = this.previewPayload[property];
        }
        for (const innerProperty in originalDataProperty) {
          if (
            innerProperty !== "handbook" &&
            property !== "renewalTrainingInformation" &&
            innerProperty !== "experienceInformation"
          ) {
            if (this.currentRoute.split("/")[2] === "certification") {
              for (let index = 0; index < this.formData.length; index++) {
                if (
                  this.formData[index].fields.formInputs !== undefined &&
                  this.formData[index].fields.formInputs.length
                ) {
                  for (
                    let i = 0;
                    i < this.formData[index].fields.formInputs.length;
                    i++
                  ) {
                    if (
                      property === "certificationPartTwoSupervisorInformation"
                    ) {
                      this.formData[index].fields.formInputs[0].inputs
                        ?.length &&
                        this.formData[
                          index
                        ].fields.formInputs[0].inputs.forEach((data: any) => {
                          data.forEach((input: any) => {
                            if (input.name === innerProperty) {
                              if (input.otherLabel) {
                                label = input.otherLabel;
                              } else {
                                label = input.subLabel
                                  ? input.subLabel
                                  : input.label;
                              }
                            }
                          });
                        });
                    } else {
                      for (
                        let j = 0;
                        j < this.formData[index].fields.formInputs[i].length;
                        j++
                      ) {
                        this.formData[index].fields.formInputs[i].forEach(
                          (data: any) => {
                            if (data.name === innerProperty) {
                              label = data.subLabel
                                ? data.subLabel
                                : data.label;
                              return data.label;
                            }
                          }
                        );
                      }
                    }
                  }
                } else {
                  if (
                    this.formData[index].fields.multiForm !== undefined &&
                    this.formData[index].fields.multiForm.length
                  ) {
                    this.formData[index].fields.multiForm[0].formInputs.forEach(
                      (data: any) => {
                        data.forEach((input: any) => {
                          if (input.name === innerProperty) {
                            if (input.otherLabel) {
                              label = input.otherLabel;
                            } else {
                              label = input.subLabel
                                ? input.subLabel
                                : input.label;
                            }
                          }
                        });
                      }
                    );
                  }
                }
              }
            } else {
              for (let index = 0; index < this.formData.length; index++) {
                if (property === "renewalSupervisorInformation") {
                  if (
                    this.formData[index].fields.multiForm !== undefined &&
                    this.formData[index].fields.multiForm.length
                  ) {
                    this.formData[index].fields.multiForm[0].formInputs.forEach(
                      (data: any) => {
                        data.forEach((input: any) => {
                          if (input.name === innerProperty) {
                            if (input.otherLabel) {
                              label = input.otherLabel;
                            } else {
                              label = input.subLabel
                                ? input.subLabel
                                : input.label;
                            }
                          }
                        });
                      }
                    );
                  }
                } else {
                  if (
                    this.formData[index].fields.formInputs !== undefined &&
                    this.formData[index].fields.formInputs.length
                  ) {
                    for (let index = 0; index < this.formData.length; index++) {
                      for (
                        let i = 0;
                        i < this.formData[index]?.fields?.formInputs?.length;
                        i++
                      ) {
                        this.formData[index].fields.formInputs[i].forEach(
                          (data: any) => {
                            if (data.name === innerProperty) {
                              label = data.otherLabel
                                ? data.otherLabel
                                : data.subLabel
                                ? data.subLabel
                                : data.label;
                              return data.label;
                            }
                          }
                        );
                      }
                    }
                  }
                }
              }
            }

            if (
              !innerProperty.includes("History") &&
              JSON.stringify(originalDataProperty[innerProperty]) !==
                JSON.stringify(previewPayloadProperty[innerProperty])
            ) {
              if (
                this.previewPayload["History"] &&
                this.previewPayload["History"].length
              ) {
                const alreadyExistsId = this.previewPayload[
                  "History"
                ].findIndex((data: any) => {
                  return data[innerProperty];
                });
                if (alreadyExistsId > -1) {
                  const duplicateDataIdx = this.previewPayload["History"][
                    alreadyExistsId
                  ][innerProperty].findIndex((duplicateData: any) => {
                    return duplicateData.isSaved === false;
                  });
                  if (duplicateDataIdx > -1) {
                    this.previewPayload["History"][alreadyExistsId][
                      innerProperty
                    ][duplicateDataIdx].newValue =
                      previewPayloadProperty[innerProperty];

                    this.previewPayload["History"][alreadyExistsId][
                      innerProperty
                    ][duplicateDataIdx].oldValue =
                      originalDataProperty[innerProperty];
                  } else {
                    this.previewPayload["History"][alreadyExistsId][
                      innerProperty
                    ].push({
                      oldValue: originalDataProperty[innerProperty],
                      newValue: previewPayloadProperty[innerProperty],
                      label: label,
                      isSaved: false,
                      fieldName: innerProperty,
                      editorDetails: {
                        name: this.userDetails.displayName,
                        email: this.userDetails.email,
                        userId: this.userDetails.userId,
                      },
                      parentName: property,
                    });
                  }
                } else {
                  this.previewPayload["History"].push({
                    [innerProperty]: [
                      {
                        oldValue: originalDataProperty[innerProperty],
                        newValue: previewPayloadProperty[innerProperty],
                        label: label,
                        isSaved: false,
                        fieldName: innerProperty,
                        editorDetails: {
                          name: this.userDetails.displayName,
                          email: this.userDetails.email,
                          userId: this.userDetails.userId,
                        },
                        parentName: property,
                      },
                    ],
                  });
                  this.editParentSlug = property;
                }
              } else {
                this.previewPayload["History"] = [
                  {
                    [innerProperty]: [
                      {
                        oldValue: originalDataProperty[innerProperty],
                        newValue: previewPayloadProperty[innerProperty],
                        label: label,
                        isSaved: false,
                        fieldName: innerProperty,
                        editorDetails: {
                          name: this.userDetails.displayName,
                          email: this.userDetails.email,
                          userId: this.userDetails.userId,
                        },
                        parentName: property,
                      },
                    ],
                  },
                ];
                this.editParentSlug = property;
              }

              if (
                this.previewPayload["History"] &&
                this.previewPayload["History"].length
              ) {
                temp = JSON.parse(
                  JSON.stringify(this.previewPayload["History"])
                );
                temp.forEach((editedData: any) => {
                  editedData[innerProperty] &&
                    editedData[innerProperty].forEach((data: any) => {
                      if (data.isSaved === false) {
                        switch (data.parentName) {
                          case "certificationPartTwoApplicationForm":
                            title = "Basic Details";
                            break;
                          case "certificationStatementOfPersonalExperience":
                            title = "Statement of Personal Experience";
                            break;
                          case "certificationApplicationDocument":
                            title = "Document Upload";
                            break;
                          case "certificationPartTwoSupervisorInformation":
                            title = "Supervisor Information";
                            break;
                          case "certificationApplicationScopeOfActivities":
                            title = "Scope of Activity";
                            break;
                          case "renewalApplicationInformation":
                            title = "Basic Details";
                            break;
                          case "renewalHandbook":
                            title = "Code of Ethics";
                            break;
                          case "certificationPartTwoRefererenceInformation":
                            title = "Reference Information";
                            break;
                          case "renewalSupervisorInformation":
                            title = "Supervisor Information";
                            break;
                        }
                        temp = JSON.parse(JSON.stringify(data));
                        if (
                          this.filesEdit.length &&
                          this.filesEdit.includes(innerProperty)
                        ) {
                          data.oldValue = data.oldValue.map((data: any) => {
                            return data.originalFileName;
                          });
                          data.newValue = data.newValue.map((data: any) => {
                            return data.originalFileName;
                          });
                        }
                        if (
                          typeof data.oldValue === "object" &&
                          typeof data.newValue === "object"
                        ) {
                          data.oldValue = data.oldValue.length
                            ? data.oldValue.join(", ")
                            : data.oldValue;
                          data.newValue = data.newValue.length
                            ? data.newValue.join(", ")
                            : data.newValue;
                        }
                        temp = data;
                        if (!this.editedFields.length) {
                          this.changesCount = 0;
                          this.editedFormNames = [data.parentName];
                          this.editedFields = [
                            {
                              parentSlug: data.parentName,
                              title: title,
                              updatedFields: [
                                {
                                  data: temp,
                                },
                              ],
                            },
                          ];
                          this.changesCount++;
                        } else {
                          const alreadyExistsId = this.editedFields.findIndex(
                            (alreadyExitsData) => {
                              return (
                                alreadyExitsData.parentSlug === data.parentName
                              );
                            }
                          );
                          let duplicateIdx: number;
                          if (alreadyExistsId > -1) {
                            duplicateIdx = this.editedFields[
                              alreadyExistsId
                            ].updatedFields.findIndex((duplicate: any) => {
                              return (
                                duplicate.data.fieldName === data.fieldName
                              );
                            });

                            if (duplicateIdx < 0) {
                              this.editedFields[
                                alreadyExistsId
                              ].updatedFields.push({
                                data: temp,
                              });
                              this.changesCount++;
                            } else {
                              this.editedFields[alreadyExistsId].updatedFields[
                                duplicateIdx
                              ] = {
                                data: temp,
                              };
                              this.isFieldsReferesh =
                                this.changeStateForFieldUpdate(
                                  this.isFieldsReferesh
                                );
                            }
                          } else {
                            this.editedFormNames.push(data.parentName);
                            this.editedFields.push({
                              parentSlug: data.parentName,
                              title: title,
                              updatedFields: [
                                {
                                  data: temp,
                                },
                              ],
                            });
                            this.changesCount++;
                          }
                        }
                        this.isFieldsReferesh = this.changeStateForFieldUpdate(
                          this.isFieldsReferesh
                        );
                      }
                      temp = data;
                    });
                });
              }
            } else {
              if (
                innerProperty !== "handbook" &&
                innerProperty !== "experienceInformation"
              ) {
                this.removeEditedFields(innerProperty, property);
              }
            }
          }
        }
      }
      this.$emit("editedData", this.previewPayload);
    }
  }

  public multiFormEditHistory(formName: string) {
    let temp: any;
    let formLabel = "";
    let title = "";
    let label = "";
    formLabel = this.currentRoute.split("/")[2] === "renewal" ? "Training" : "";
    this.previewPayload[formName].forEach((previewData: any, index: number) => {
      for (const innerProperty in this.previewPayload[formName][index]) {
        for (let index = 0; index < this.formData.length; index++) {
          if (
            this.formData[index].formInputs !== undefined &&
            this.formData[index].formInputs.length
          ) {
            for (let index = 0; index < this.formData.length; index++) {
              for (let i = 0; i < this.formData[index].formInputs.length; i++) {
                this.formData[index].formInputs[i].forEach((data: any) => {
                  if (data.name === innerProperty) {
                    label = data.subLabel ? data.subLabel : data.label;
                    return data.label;
                  }
                });
              }
            }
          }
        }
        if (
          !this.originalData[formName][index] ||
          !this.originalData[formName].length ||
          (this.originalData[formName].length &&
            JSON.stringify(
              this.originalData[formName][index][innerProperty]
            ) !==
              JSON.stringify(
                this.previewPayload[formName][index][innerProperty]
              ))
        ) {
          if (
            this.previewPayload["History"] &&
            this.previewPayload["History"].length
          ) {
            if (
              !this.previewPayload[formName][index].isNewlyAdded &&
              !this.previewPayload[formName][index].isRemoved
            ) {
              const alreadyExistsId = this.previewPayload["History"].findIndex(
                (data: any) => {
                  return data[innerProperty + "_" + index];
                }
              );
              if (alreadyExistsId > -1) {
                const duplicateDataIdx = this.previewPayload["History"][
                  alreadyExistsId
                ][innerProperty + "_" + index].findIndex(
                  (duplicateData: any) => {
                    return duplicateData.isSaved === false;
                  }
                );
                if (duplicateDataIdx > -1) {
                  this.previewPayload["History"][alreadyExistsId][
                    innerProperty + "_" + index
                  ][duplicateDataIdx].newValue =
                    this.previewPayload[formName][index][innerProperty];
                } else {
                  this.previewPayload["History"][alreadyExistsId][
                    innerProperty + "_" + index
                  ].push({
                    oldValue: this.originalData[formName][index][innerProperty],
                    newValue:
                      this.previewPayload[formName][index][innerProperty],
                    label: label,
                    isSaved: false,
                    fieldName: innerProperty + "_" + index,
                    editorDetails: {
                      name: this.userDetails.displayName,
                      email: this.userDetails.email,
                      userId: this.userDetails.userId,
                    },
                    parentName: formName,
                  });
                }
              } else {
                this.previewPayload["History"].push({
                  [innerProperty + "_" + index]: [
                    {
                      oldValue:
                        this.originalData[formName][index][innerProperty],
                      newValue:
                        this.previewPayload[formName][index][innerProperty],
                      label: label,
                      isSaved: false,
                      fieldName: innerProperty + "_" + index,
                      editorDetails: {
                        name: this.userDetails.displayName,
                        email: this.userDetails.email,
                        userId: this.userDetails.userId,
                      },
                      parentName: formName,
                    },
                  ],
                });
                this.editParentSlug = formName;
              }
            }
            if (this.previewPayload[formName][index].isNewlyAdded === true) {
              const alreadyExists = this.previewPayload["History"].findIndex(
                (data: any) => {
                  return data["new" + formLabel + "Idx"];
                }
              );
              if (alreadyExists < 0) {
                this.previewPayload["History"].push({
                  ["new" + formLabel + "Idx"]: [
                    {
                      index: index,
                      editorDetails: {
                        name: this.userDetails.displayName,
                        email: this.userDetails.email,
                        userId: this.userDetails.userId,
                      },
                      isSaved: false,
                    },
                  ],
                });
              } else {
                const findDuplicateIdx = this.previewPayload["History"][
                  alreadyExists
                ]["new" + formLabel + "Idx"].findIndex((duplicateIdx: any) => {
                  return duplicateIdx.index === index;
                });
                if (findDuplicateIdx < 0) {
                  this.previewPayload["History"][alreadyExists][
                    "new" + formLabel + "Idx"
                  ].push({
                    index: index,
                    editorDetails: {
                      name: this.userDetails.displayName,
                      email: this.userDetails.email,
                      userId: this.userDetails.userId,
                    },
                    isSaved: false,
                  });
                }
              }
            }
          } else {
            if (
              !this.previewPayload[formName][index].isNewlyAdded &&
              !this.previewPayload[formName][index].isRemoved
            ) {
              this.previewPayload["History"] = [
                {
                  [innerProperty + "_" + index]: [
                    {
                      oldValue:
                        this.originalData[formName][index][innerProperty],
                      newValue:
                        this.previewPayload[formName][index][innerProperty],
                      label: label,
                      isSaved: false,
                      fieldName: innerProperty + "_" + index,
                      editorDetails: {
                        name: this.userDetails.displayName,
                        email: this.userDetails.email,
                        userId: this.userDetails.userId,
                      },
                      parentName: formName,
                    },
                  ],
                },
              ];
              this.editParentSlug = formName;
            }
            if (this.previewPayload[formName][index].isNewlyAdded === true) {
              this.previewPayload["History"] = [
                {
                  ["new" + formLabel + "Idx"]: [
                    {
                      index: index,
                      editorDetails: {
                        name: this.userDetails.displayName,
                        email: this.userDetails.email,
                        userId: this.userDetails.userId,
                      },
                      isSaved: false,
                    },
                  ],
                },
              ];
            }
          }
          if (
            this.previewPayload["History"] &&
            this.previewPayload["History"].length
          ) {
            temp = JSON.parse(JSON.stringify(this.previewPayload["History"]));
            temp.forEach((editedData: any) => {
              editedData[innerProperty + "_" + index] &&
                editedData[innerProperty + "_" + index].forEach((data: any) => {
                  if (data.isSaved === false) {
                    switch (formName) {
                      case "renewalTrainingInformation":
                        title = "Training Information";
                        break;
                      default:
                        break;
                    }
                    temp = JSON.parse(JSON.stringify(data));
                    if (!temp.isRemoved && !temp.isNewlyAdded) {
                      if (
                        this.filesEdit.length &&
                        this.filesEdit.includes(innerProperty)
                      ) {
                        data.oldValue = data.oldValue.map((data: any) => {
                          return data.originalFileName;
                        });
                        data.newValue = data.newValue.map((data: any) => {
                          return data.originalFileName;
                        });
                      }
                      if (
                        typeof data.oldValue === "object" &&
                        typeof data.newValue === "object"
                      ) {
                        data.oldValue = data.oldValue.length
                          ? data.oldValue.join(", ")
                          : data.oldValue;
                        data.newValue = data.newValue.length
                          ? data.newValue.join(", ")
                          : data.newValue;
                      }
                      data.indexPosition = index;
                      temp = data;
                      if (!this.editedFields.length) {
                        this.changesCount = 0;
                        this.editedFormNames = [formName];
                        this.editedFields = [
                          {
                            parentSlug: formName,
                            title: title,
                            updatedFields: [
                              {
                                data: {
                                  indexPosition: index,
                                  trainingData: [temp],
                                  trainingName:
                                    this.previewPayload[formName][index][
                                      "trainingName"
                                    ],
                                },
                              },
                            ],
                          },
                        ];
                        this.changesCount++;
                      } else {
                        const alreadyExistsId = this.editedFields.findIndex(
                          (data) => {
                            return data.parentSlug === formName;
                          }
                        );
                        let duplicateIdx: number;
                        if (alreadyExistsId > -1) {
                          duplicateIdx = this.editedFields[
                            alreadyExistsId
                          ].updatedFields.findIndex((updatedData: any) => {
                            return (
                              updatedData.data.indexPosition ===
                              data.indexPosition
                            );
                          });
                          if (duplicateIdx > -1) {
                            const fieldIdx = this.editedFields[
                              alreadyExistsId
                            ].updatedFields[
                              duplicateIdx
                            ].data.trainingData.findIndex((training: any) => {
                              return training.fieldName === data.fieldName;
                            });
                            if (fieldIdx > -1) {
                              this.editedFields[alreadyExistsId].updatedFields[
                                duplicateIdx
                              ].data.trainingData[fieldIdx] = temp;
                              this.editedFields[alreadyExistsId].updatedFields[
                                duplicateIdx
                              ].data.trainingName =
                                this.previewPayload[formName][index][
                                  "trainingName"
                                ];
                            } else {
                              this.editedFields[alreadyExistsId].updatedFields[
                                duplicateIdx
                              ].data.trainingData.push(temp);
                              this.changesCount++;
                            }
                          } else {
                            this.editedFields[
                              alreadyExistsId
                            ].updatedFields.push({
                              data: {
                                indexPosition: index,
                                trainingData: [temp],
                                trainingName:
                                  this.previewPayload[formName][index][
                                    "trainingName"
                                  ],
                              },
                            });
                            this.changesCount++;
                          }
                        } else {
                          this.editedFormNames.push(formName);
                          this.editedFields.push({
                            parentSlug: formName,
                            title: title,
                            updatedFields: [
                              {
                                data: {
                                  indexPosition: index,
                                  trainingData: [temp],
                                  trainingName:
                                    this.previewPayload[formName][index][
                                      "trainingName"
                                    ],
                                },
                              },
                            ],
                          });
                          this.changesCount++;
                        }
                      }
                    }
                    this.isFieldsReferesh = this.changeStateForFieldUpdate(
                      this.isFieldsReferesh
                    );
                  }
                  temp = data;
                });
            });
          }
        } else {
          this.removeMultiFormEditHistoryEditedFields(innerProperty, index);
        }
      }
    });

    if (
      this.previewPayload["History"] &&
      this.previewPayload["History"].length
    ) {
      const editedTemp = JSON.parse(
        JSON.stringify(this.previewPayload[formName])
      );
      editedTemp.forEach((editedTempData: any, idx: number) => {
        if (editedTempData.isSaved === false) {
          switch (formName) {
            case "renewalTrainingInformation":
              title = "Training Information";
              break;
            default:
              break;
          }
          if (editedTempData.isRemoved === true) {
            if (!this.editedFields.length) {
              this.changesCount = 0;
              this.editedFormNames = [formName];
              this.editedFields = [
                {
                  parentSlug: formName,
                  title: title,
                  updatedFields: [
                    {
                      data: {
                        trainingName:
                          idx < this.originalData[formName].length
                            ? this.originalData[formName][idx].trainingName
                            : editedTempData.trainingName,
                        isNewlyAdded: false,
                        isRemoved: true,
                      },
                    },
                  ],
                },
              ];
              this.changesCount++;
            } else {
              const alreadyExistsId = this.editedFields.findIndex(
                (alreadyExitsData) => {
                  return alreadyExitsData.parentSlug === formName;
                }
              );
              if (alreadyExistsId < 0) {
                this.editedFormNames.push(formName);
                this.editedFields.push({
                  parentSlug: formName,
                  title: title,
                  updatedFields: [
                    {
                      data: {
                        trainingName:
                          idx < this.originalData[formName].length
                            ? this.originalData[formName][idx].trainingName
                            : editedTempData.trainingName,
                        isNewlyAdded: false,
                        isRemoved: true,
                      },
                    },
                  ],
                });
                this.changesCount++;
              } else {
                const duplicateDataIdx = this.editedFields[
                  alreadyExistsId
                ].updatedFields.findIndex((updatedData: any) => {
                  if (
                    !updatedData.data.trainingData &&
                    updatedData.data.trainingName
                  ) {
                    const getTrainingName =
                      idx < this.originalData[formName].length
                        ? this.originalData[formName][idx].trainingName
                        : editedTempData.trainingName;
                    return updatedData.data.trainingName === getTrainingName;
                  }
                });
                if (duplicateDataIdx < 0) {
                  this.editedFields[alreadyExistsId].updatedFields.push({
                    data: {
                      trainingName:
                        idx < this.originalData[formName].length
                          ? this.originalData[formName][idx].trainingName
                          : editedTempData.trainingName,
                      isNewlyAdded: false,
                      isRemoved: true,
                    },
                  });
                  this.changesCount++;
                }
              }
            }
          }
          if (editedTempData.isNewlyAdded === true) {
            if (!this.editedFields.length) {
              this.changesCount = 0;
              this.editedFormNames = [formName];
              this.editedFields = [
                {
                  parentSlug: formName,
                  title: title,
                  updatedFields: [
                    {
                      data: {
                        trainingName: editedTempData.trainingName,
                        isNewlyAdded: true,
                        isRemoved: false,
                        trainingIdx: idx,
                      },
                    },
                  ],
                },
              ];
              this.changesCount++;
            } else {
              const alreadyExistsId = this.editedFields.findIndex(
                (alreadyExitsData) => {
                  return alreadyExitsData.parentSlug === formName;
                }
              );
              if (alreadyExistsId < 0) {
                this.editedFormNames.push(formName);
                this.editedFields.push({
                  parentSlug: formName,
                  title: title,
                  updatedFields: [
                    {
                      data: {
                        trainingName: editedTempData.trainingName,
                        isNewlyAdded: true,
                        isRemoved: false,
                        trainingIdx: idx,
                      },
                    },
                  ],
                });
                this.changesCount++;
              } else {
                const duplicateDataIdx = this.editedFields[
                  alreadyExistsId
                ].updatedFields.findIndex((updatedData: any) => {
                  if (!updatedData.data.trainingData) {
                    return updatedData.data.trainingIdx === idx;
                  }
                });
                if (duplicateDataIdx < 0) {
                  this.editedFields[alreadyExistsId].updatedFields.push({
                    data: {
                      trainingName: editedTempData.trainingName,
                      isNewlyAdded: true,
                      isRemoved: false,
                      trainingIdx: idx,
                    },
                  });
                  this.changesCount++;
                } else {
                  this.editedFields[alreadyExistsId].updatedFields[
                    duplicateDataIdx
                  ].data.trainingName = editedTempData.trainingName;
                }
              }
            }
          }
        }
      });
    }
  }

  public scopeOfActivities(formName: string) {
    let label = "";
    let title = "";
    let temp: any;
    for (const innerProperty in this.previewPayload[formName]) {
      if (innerProperty === "handbook") {
        for (const SOAproperty in this.previewPayload[formName][
          innerProperty
        ]) {
          for (const SOAinnerProperty in this.previewPayload[formName][
            innerProperty
          ][SOAproperty]) {
            this.scopeOfActivityFormData.forEach(
              (data: ScopeOfActivitieData) => {
                data.attributes?.form_fields?.fields?.formInputs.forEach(
                  (formInputData: any) => {
                    formInputData.forEach((inputData: FromFieldData) => {
                      if (
                        inputData.otherLabel ===
                        this.SOALabels[SOAinnerProperty]
                      ) {
                        label = inputData.otherLabel;
                      }
                    });
                  }
                );
              }
            );
            if (
              !SOAinnerProperty.includes("History") &&
              this.originalData[formName][innerProperty][SOAproperty][
                SOAinnerProperty
              ].toString() !==
                this.previewPayload[formName][innerProperty][SOAproperty][
                  SOAinnerProperty
                ].toString()
            ) {
              if (
                this.previewPayload["History"] &&
                this.previewPayload["History"].length
              ) {
                const alreadyExistsId = this.previewPayload[
                  "History"
                ].findIndex((alreadyExistsDdata: any) => {
                  return alreadyExistsDdata[SOAinnerProperty];
                });
                if (alreadyExistsId > -1) {
                  const duplicateDataIdx = this.previewPayload["History"][
                    alreadyExistsId
                  ][SOAinnerProperty].findIndex((duplicateData: any) => {
                    return duplicateData.isSaved === false;
                  });
                  if (duplicateDataIdx > -1) {
                    this.previewPayload["History"][alreadyExistsId][
                      SOAinnerProperty
                    ][duplicateDataIdx].newValue =
                      this.previewPayload[formName][innerProperty][SOAproperty][
                        SOAinnerProperty
                      ];
                  } else {
                    this.previewPayload["History"][alreadyExistsId][
                      SOAinnerProperty
                    ].push({
                      oldValue:
                        this.originalData[formName][innerProperty][SOAproperty][
                          SOAinnerProperty
                        ],
                      newValue:
                        this.previewPayload[formName][innerProperty][
                          SOAproperty
                        ][SOAinnerProperty],
                      label: label,
                      isSaved: false,
                      fieldName: SOAinnerProperty,
                      editorDetails: {
                        name: this.userDetails.displayName,
                        email: this.userDetails.email,
                        userId: this.userDetails.userId,
                      },
                      parentName: formName,
                    });
                  }
                } else {
                  this.previewPayload["History"].push({
                    [SOAinnerProperty]: [
                      {
                        oldValue:
                          this.originalData[formName][innerProperty][
                            SOAproperty
                          ][SOAinnerProperty],
                        newValue:
                          this.previewPayload[formName][innerProperty][
                            SOAproperty
                          ][SOAinnerProperty],
                        label: label,
                        isSaved: false,
                        fieldName: SOAinnerProperty,
                        editorDetails: {
                          name: this.userDetails.displayName,
                          email: this.userDetails.email,
                          userId: this.userDetails.userId,
                        },
                        parentName: formName,
                      },
                    ],
                  });
                  this.editParentSlug = formName;
                }
              } else {
                this.previewPayload["History"] = [
                  {
                    [SOAinnerProperty]: [
                      {
                        oldValue:
                          this.originalData[formName][innerProperty][
                            SOAproperty
                          ][SOAinnerProperty],
                        newValue:
                          this.previewPayload[formName][innerProperty][
                            SOAproperty
                          ][SOAinnerProperty],
                        label: label,
                        isSaved: false,
                        fieldName: SOAinnerProperty,
                        editorDetails: {
                          name: this.userDetails.displayName,
                          email: this.userDetails.email,
                          userId: this.userDetails.userId,
                        },
                        parentName: formName,
                      },
                    ],
                  },
                ];
                this.editParentSlug = formName;
              }
              if (
                this.previewPayload["History"] &&
                this.previewPayload["History"].length
              ) {
                temp = JSON.parse(
                  JSON.stringify(this.previewPayload["History"])
                );
                temp.forEach((editedData: any) => {
                  editedData[SOAinnerProperty] &&
                    editedData[SOAinnerProperty].forEach((SOAData: any) => {
                      if (SOAData.isSaved === false) {
                        switch (SOAData.parentName) {
                          case "scopeOfActivity":
                            title = "Scope of Activity";
                            break;
                          case "codeOfEthics":
                            title = "Code of Ethics";
                            break;
                          case "handBookDocument":
                            title = "Handbook";
                            break;
                          default:
                            break;
                        }
                        temp = JSON.parse(JSON.stringify(SOAData));
                        if (
                          this.filesEdit.length &&
                          this.filesEdit.includes(SOAinnerProperty)
                        ) {
                          SOAData.oldValue = SOAData.oldValue.map(
                            (data: any) => {
                              return data.originalFileName;
                            }
                          );
                          SOAData.newValue = SOAData.newValue.map(
                            (data: any) => {
                              return data.originalFileName;
                            }
                          );
                        }
                        if (
                          typeof SOAData.oldValue === "object" &&
                          typeof SOAData.newValue === "object"
                        ) {
                          SOAData.oldValue = SOAData.oldValue.length
                            ? SOAData.oldValue.join(", ")
                            : SOAData.oldValue;
                          SOAData.newValue = SOAData.newValue.length
                            ? SOAData.newValue.join(", ")
                            : SOAData.newValue;
                        }
                        temp = SOAData;
                        if (!this.editedFields.length) {
                          this.changesCount = 0;
                          this.editedFormNames = [SOAData.parentName];
                          this.editedFields = [
                            {
                              parentSlug: SOAData.parentName,
                              title: title,
                              updatedFields: [
                                {
                                  data: temp,
                                },
                              ],
                            },
                          ];
                          this.changesCount++;
                        } else {
                          const alreadyExistsId = this.editedFields.findIndex(
                            (alreadyExitsData) => {
                              return (
                                alreadyExitsData.parentSlug ===
                                SOAData.parentName
                              );
                            }
                          );
                          let duplicateIdx: number;
                          if (alreadyExistsId > -1) {
                            duplicateIdx = this.editedFields[
                              alreadyExistsId
                            ].updatedFields.findIndex((duplicate: any) => {
                              return (
                                duplicate.data.fieldName === SOAData.fieldName
                              );
                            });

                            if (duplicateIdx < 0) {
                              this.editedFields[
                                alreadyExistsId
                              ].updatedFields.push({
                                data: temp,
                              });
                              this.changesCount++;
                            } else {
                              this.editedFields[alreadyExistsId].updatedFields[
                                duplicateIdx
                              ] = {
                                data: temp,
                              };
                              this.isFieldsReferesh =
                                this.changeStateForFieldUpdate(
                                  this.isFieldsReferesh
                                );
                            }
                          } else {
                            this.editedFormNames.push(SOAData.parentName);
                            this.editedFields.push({
                              parentSlug: SOAData.parentName,
                              title: title,
                              updatedFields: [
                                {
                                  data: temp,
                                },
                              ],
                            });
                            this.changesCount++;
                          }
                        }
                        this.isFieldsReferesh = this.changeStateForFieldUpdate(
                          this.isFieldsReferesh
                        );
                      }
                      temp = SOAData;
                    });
                });
              }
            } else {
              this.removeEditedFields(SOAinnerProperty);
            }
          }
        }

        for (const SOAproperty in this.previewPayload[formName][
          innerProperty
        ]) {
          for (const SOAinnerProperty in this.previewPayload[formName][
            innerProperty
          ][SOAproperty]) {
            if (SOAinnerProperty.includes("History")) {
              switch (formName) {
                case "scopeOfActivity":
                  title = "Scope of Activity";
                  break;
                case "codeOfEthics":
                  title = "Code of Ethics";
                  break;
                case "handBookDocument":
                  title = "Handbook";
                  break;
                default:
                  break;
              }
              temp = JSON.parse(
                JSON.stringify(
                  this.previewPayload[formName][innerProperty][SOAproperty][
                    SOAinnerProperty
                  ]
                )
              );
              temp.forEach((data: any) => {
                if (data.isSaved === false) {
                  if (
                    this.filesEdit.length &&
                    this.filesEdit.includes(
                      SOAinnerProperty.replace("History", "")
                    )
                  ) {
                    data.oldValue = data.oldValue.map((data: any) => {
                      return data.originalFileName;
                    });
                    data.newValue = data.newValue.map((data: any) => {
                      return data.originalFileName;
                    });
                  }
                  if (
                    typeof data.oldValue === "object" &&
                    typeof data.newValue === "object"
                  ) {
                    data.oldValue = data.oldValue.length
                      ? data.oldValue.join(", ")
                      : data.oldValue;
                    data.newValue = data.newValue.length
                      ? data.newValue.join(", ")
                      : data.newValue;
                  }
                  temp = data;
                  if (!this.editedFields.length) {
                    this.changesCount = 0;
                    this.editedFormNames = [formName];
                    this.editedFields = [
                      {
                        parentSlug: formName,
                        title: title,
                        updatedFields: [
                          {
                            data: temp,
                          },
                        ],
                      },
                    ];
                    this.changesCount++;
                  } else {
                    const alreadyExistsId = this.editedFields.findIndex(
                      (alreadyExistsData) => {
                        return alreadyExistsData.parentSlug === formName;
                      }
                    );
                    let duplicateIdx: number;
                    if (alreadyExistsId > -1) {
                      duplicateIdx = this.editedFields[
                        alreadyExistsId
                      ].updatedFields.findIndex((updatedData: any) => {
                        return updatedData.data.fieldName === data.fieldName;
                      });
                      if (duplicateIdx > -1) {
                        this.editedFields[alreadyExistsId].updatedFields[
                          duplicateIdx
                        ].data = temp;
                      } else {
                        this.editedFields[alreadyExistsId].updatedFields.push({
                          data: temp,
                        });
                        this.changesCount++;
                      }
                    } else {
                      this.editedFormNames.push(formName);
                      this.editedFields.push({
                        parentSlug: formName,
                        title: title,
                        updatedFields: [
                          {
                            data: temp,
                          },
                        ],
                      });
                      this.changesCount++;
                    }
                  }
                  this.isFieldsReferesh = this.changeStateForFieldUpdate(
                    this.isFieldsReferesh
                  );
                }
              });
            }
          }
        }
      }
    }
    this.$emit("editedData", this.previewPayload);
  }

  public basicExperienceInformation(formName: string) {
    let label = "";
    let subTitle = "";
    let temp: any;
    for (const innerProperty in this.previewPayload[formName]) {
      if (innerProperty === "experienceInformation") {
        for (const experienceProperty in this.previewPayload[formName][
          innerProperty
        ]) {
          for (const experienceInnerProperty in this.previewPayload[formName][
            innerProperty
          ][experienceProperty]) {
            if (
              !experienceInnerProperty.includes("History") &&
              this.originalData[formName][innerProperty][experienceProperty][
                experienceInnerProperty
              ].toString() !==
                this.previewPayload[formName][innerProperty][
                  experienceProperty
                ][experienceInnerProperty].toString()
            ) {
              const formData =
                this.experienceData[0].attributes.fields.formInputs;
              const inputDetials: any = formData
                .flat(1)
                .find((item: any) => item.name === experienceProperty);
              const subFormTitle = inputDetials.label;
              const subFormName = inputDetials.name;
              const fieldGroups = inputDetials.propBind.fieldGroups[0];
              const fieldsDetials: any = fieldGroups.inputs
                .flat(1)
                .find((item: any) => item.name === experienceInnerProperty);
              label = fieldsDetials.otherLabel
                ? fieldsDetials.otherLabel
                : fieldsDetials.label;
              if (
                this.previewPayload["History"] &&
                this.previewPayload["History"].length
              ) {
                const alreadyExistsId = this.previewPayload[
                  "History"
                ].findIndex((alreadyExistsDdata: any) => {
                  return alreadyExistsDdata[
                    subFormName + "_" + experienceInnerProperty
                  ];
                });
                if (alreadyExistsId > -1) {
                  const duplicateDataIdx = this.previewPayload["History"][
                    alreadyExistsId
                  ][subFormName + "_" + experienceInnerProperty].findIndex(
                    (duplicateData: any) => {
                      return duplicateData.isSaved === false;
                    }
                  );
                  if (duplicateDataIdx > -1) {
                    this.previewPayload["History"][alreadyExistsId][
                      subFormName + "_" + experienceInnerProperty
                    ][duplicateDataIdx].newValue =
                      this.previewPayload[formName][innerProperty][
                        experienceProperty
                      ][experienceInnerProperty];
                  } else {
                    this.previewPayload["History"][alreadyExistsId][
                      subFormName + "_" + experienceInnerProperty
                    ].push({
                      oldValue:
                        this.originalData[formName][innerProperty][
                          experienceProperty
                        ][experienceInnerProperty],
                      newValue:
                        this.previewPayload[formName][innerProperty][
                          experienceProperty
                        ][experienceInnerProperty],
                      label: label,
                      isSaved: false,
                      fieldName: experienceInnerProperty,
                      subFormName: subFormName,
                      subFormTitle: subFormTitle,
                      editorDetails: {
                        name: this.userDetails.displayName,
                        email: this.userDetails.email,
                        userId: this.userDetails.userId,
                      },
                      parentName: formName,
                    });
                  }
                } else {
                  this.previewPayload["History"] &&
                    this.previewPayload["History"].push({
                      [subFormName + "_" + experienceInnerProperty]: [
                        {
                          oldValue:
                            this.originalData[formName][innerProperty][
                              experienceProperty
                            ][experienceInnerProperty],
                          newValue:
                            this.previewPayload[formName][innerProperty][
                              experienceProperty
                            ][experienceInnerProperty],
                          label: label,
                          isSaved: false,
                          fieldName: experienceInnerProperty,
                          subFormName: subFormName,
                          subFormTitle: subFormTitle,
                          editorDetails: {
                            name: this.userDetails.displayName,
                            email: this.userDetails.email,
                            userId: this.userDetails.userId,
                          },
                          parentName: formName,
                        },
                      ],
                    });
                  this.editParentSlug = formName;
                }
              } else {
                this.previewPayload["History"] = [
                  {
                    [subFormName + "_" + experienceInnerProperty]: [
                      {
                        oldValue:
                          this.originalData[formName][innerProperty][
                            experienceProperty
                          ][experienceInnerProperty],
                        newValue:
                          this.previewPayload[formName][innerProperty][
                            experienceProperty
                          ][experienceInnerProperty],
                        label: label,
                        isSaved: false,
                        fieldName: experienceInnerProperty,
                        subFormName: subFormName,
                        subFormTitle: subFormTitle,
                        editorDetails: {
                          name: this.userDetails.displayName,
                          email: this.userDetails.email,
                          userId: this.userDetails.userId,
                        },
                        parentName: formName,
                      },
                    ],
                  },
                ];
                this.editParentSlug = formName;
              }
              if (
                this.previewPayload["History"] &&
                this.previewPayload["History"].length
              ) {
                temp = JSON.parse(
                  JSON.stringify(this.previewPayload["History"])
                );
                temp.forEach((editedData: any) => {
                  editedData[subFormName + "_" + experienceInnerProperty] &&
                    editedData[
                      subFormName + "_" + experienceInnerProperty
                    ].forEach((experienceData: any) => {
                      if (experienceData.isSaved === false) {
                        switch (experienceData.subFormName) {
                          case "familySupportSpecialist":
                            subTitle = "Family Support Specialist";
                            break;
                          case "supportGroupFacilitator":
                            subTitle = "Support Group Facilitator";
                            break;
                          case "caregiverEducator":
                            subTitle = "Caregiver Educator";
                            break;
                          case "otherRelevantExperience":
                            subTitle = "Other Relevant Experience";
                            break;
                          default:
                            break;
                        }
                        temp = JSON.parse(JSON.stringify(experienceData));
                        if (
                          this.filesEdit.length &&
                          this.filesEdit.includes(experienceInnerProperty)
                        ) {
                          experienceData.oldValue = experienceData.oldValue.map(
                            (data: any) => {
                              return data.originalFileName;
                            }
                          );
                          experienceData.newValue = experienceData.newValue.map(
                            (data: any) => {
                              return data.originalFileName;
                            }
                          );
                        }
                        if (
                          typeof experienceData.oldValue === "object" &&
                          typeof experienceData.newValue === "object"
                        ) {
                          experienceData.oldValue = experienceData.oldValue
                            .length
                            ? experienceData.oldValue.join(", ")
                            : experienceData.oldValue;
                          experienceData.newValue = experienceData.newValue
                            .length
                            ? experienceData.newValue.join(", ")
                            : experienceData.newValue;
                        }
                        temp = experienceData;
                        if (!this.editedFields.length) {
                          this.changesCount = 0;
                          this.editedFormNames = [experienceData.parentName];
                          this.editedFields = [
                            {
                              parentSlug: experienceData.parentName,
                              title: "Basic Details",

                              updatedFields: [
                                {
                                  data: [temp],
                                  subTitle: subTitle,
                                  subFormName: temp.subFormName,
                                },
                              ],
                            },
                          ];

                          this.changesCount++;
                        } else {
                          const duplicateIndex = this.editedFields.findIndex(
                            (data: any) => {
                              return (
                                data.parentSlug === experienceData.parentName &&
                                data.title === "Basic Details"
                              );
                            }
                          );
                          if (duplicateIndex > -1) {
                            const sectionIndex = this.editedFields[
                              duplicateIndex
                            ].updatedFields.findIndex((element: any) => {
                              return (
                                element.subTitle === subTitle &&
                                element.subFormName === temp.subFormName
                              );
                            });

                            if (sectionIndex > -1) {
                              const fieldIndex = this.editedFields[
                                duplicateIndex
                              ].updatedFields[sectionIndex].data.findIndex(
                                (editedElement: any) => {
                                  return (
                                    editedElement.fieldName === temp.fieldName
                                  );
                                }
                              );
                              if (fieldIndex > -1) {
                                this.editedFields[duplicateIndex].updatedFields[
                                  sectionIndex
                                ].data[fieldIndex].newValue =
                                  this.previewPayload[formName][innerProperty][
                                    experienceProperty
                                  ][experienceInnerProperty];
                              } else {
                                this.editedFields[duplicateIndex].updatedFields[
                                  sectionIndex
                                ].data.push(temp);
                              }
                            } else {
                              this.editedFields[
                                duplicateIndex
                              ].updatedFields.push({
                                data: [temp],
                                subTitle: subTitle,
                                subFormName: temp.subFormName,
                              });
                              this.changesCount++;
                            }
                          }
                        }
                      }
                    });
                });
              }
            } else {
              this.removeEditedFields(experienceInnerProperty);
            }
          }
        }
      }
    }
  }

  public changeStateForFieldUpdate(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  public removeEditedFields(field: string, property = "") {
    let idx: number;
    this.editedFields.forEach((data, index) => {
      data.updatedFields.map((element: any, indexi: number) => {
        if (element != undefined) {
          if (
            element.data.fieldName === field &&
            data.parentSlug === property
          ) {
            idx = indexi;
            this.editedFormNames.forEach((formName: string) => {
              if (
                formName &&
                this.editedFormNames === element.data.parentName
              ) {
                this.previewPayload["History"].forEach(
                  (editedData: any, editedIdx: number) => {
                    editedData[field] &&
                      editedData[field].forEach(
                        (fieldData: any, fieldIdx: number) => {
                          if (fieldData.isSaved === false) {
                            this.previewPayload["History"][editedIdx][
                              field
                            ].splice(fieldIdx, 1);
                          }
                          if (
                            !this.previewPayload["History"][editedIdx][field]
                              .length
                          ) {
                            this.previewPayload["History"].splice(editedIdx, 1);
                          }
                          if (!this.previewPayload["History"].length) {
                            delete this.previewPayload["History"];
                          }
                        }
                      );
                  }
                );
              }
            });
            // data.updatedFields.splice(idx, 1);
            // this.changesCount--;
            if (!data.updatedFields.length) {
              this.editedFormNames.forEach((name, indexi) => {
                if (name === this.getCurrentParentSlug[this.tabSelect]) {
                  this.editedFormNames.splice(indexi, 1);
                }
              });
              this.editedFields.splice(index, 1);
            }
            this.isFieldsReferesh = this.changeStateForFieldUpdate(
              this.isFieldsReferesh
            );
          }
        }
      });
    });
  }

  public removeMultiFormEditHistoryEditedFields(
    fieldName: string,
    dataIdx: number
  ) {
    let formLabel = "";
    formLabel = this.currentRoute.split("/")[2] === "renewal" ? "Training" : "";
    let idx: number;
    this.editedFields.forEach((data, index) => {
      data.updatedFields.map((element: any, indexi: number) => {
        if (element != undefined && element.data.trainingData?.length) {
          element.data.trainingData.forEach(
            (trainingData: any, indexj: number) => {
              if (trainingData.indexPosition === dataIdx) {
                idx = indexi;
                this.editedFormNames.forEach((formName: string) => {
                  if (formName) {
                    this.previewPayload["History"] &&
                      this.previewPayload["History"].forEach(
                        (historyData: any, historyIdx: number) => {
                          const revokeIdx =
                            historyData[fieldName + "_" + dataIdx] &&
                            historyData[fieldName + "_" + dataIdx].findIndex(
                              (revokeData: any) => {
                                return (
                                  revokeData.fieldName ===
                                    fieldName + "_" + dataIdx &&
                                  revokeData.isSaved === false
                                );
                              }
                            );
                          if (revokeIdx > -1) {
                            historyData[fieldName + "_" + dataIdx].splice(
                              revokeIdx,
                              1
                            );
                            if (
                              !historyData[fieldName + "_" + dataIdx].length
                            ) {
                              delete historyData[fieldName + "_" + dataIdx];
                              if (
                                !Object.keys(
                                  this.previewPayload["History"][historyIdx]
                                ).length
                              ) {
                                this.previewPayload["History"].splice(idx, 1);
                                if (!this.previewPayload["History"].length) {
                                  delete this.previewPayload["History"];
                                }
                              }
                            }
                            element.data.trainingData.splice(indexj, 1);
                            this.changesCount--;
                            if (!element.data.trainingData.length) {
                              data.updatedFields.splice(idx, 1);
                              if (!data.updatedFields.length) {
                                this.editedFormNames.forEach((name, indexk) => {
                                  if (name === formName) {
                                    this.editedFormNames.splice(indexk, 1);
                                  }
                                });
                                this.editedFields.splice(index, 1);
                              }
                            }
                            this.isFieldsReferesh =
                              this.changeStateForFieldUpdate(
                                this.isFieldsReferesh
                              );
                          }
                        }
                      );
                  }
                });
              }
            }
          );
        }
      });
    });
  }

  public revertChanges() {
    this.editedFields = [];
    this.changesCount = 0;
    this.editApplication = false;
    this.editedFormNames.forEach((formName: string) => {
      for (const innerProperty in this.previewPayload[formName]) {
        if (innerProperty.includes("History")) {
          const unSavedIdx = this.previewPayload[formName][
            innerProperty
          ].findIndex((element: any) => {
            return element.isSaved === false;
          });
          this.previewPayload[formName][innerProperty].splice(unSavedIdx, 1);
          if (!this.previewPayload[formName][innerProperty].length) {
            delete this.previewPayload[formName][innerProperty];
          }
        }
      }
    });
    this.$emit("editedData", this.previewPayload);
    this.$emit("isRevertedChanges", true);
    this.closeBackdrop();
  }

  @Watch("removeMultiForm")
  public removeTrainingInfo() {
    let formLabel = "";
    formLabel = this.currentRoute.split("/")[2] === "renewal" ? "Training" : "";
    if (!this.removeMultiForm.data.isNewlyAdded) {
      if (
        this.previewPayload["History"] &&
        this.previewPayload["History"].length
      ) {
        for (const prop in this.previewPayload[this.removeMultiForm.formName][
          this.removeMultiForm.index
        ]) {
          this.previewPayload["History"].forEach(
            (historyData: any, idx: number) => {
              if (historyData[prop + "_" + this.removeMultiForm.index]) {
                const removedUnsavedIdx = historyData[
                  prop + "_" + this.removeMultiForm.index
                ].findIndex((data: any) => {
                  return data.isSaved === false;
                });
                if (removedUnsavedIdx > -1) {
                  historyData[prop + "_" + this.removeMultiForm.index].splice(
                    removedUnsavedIdx,
                    1
                  );
                  if (
                    !historyData[prop + "_" + this.removeMultiForm.index].length
                  ) {
                    delete historyData[prop + "_" + this.removeMultiForm.index];
                    if (
                      !Object.keys(this.previewPayload["History"][idx]).length
                    ) {
                      this.previewPayload["History"].splice(idx, 1);
                    }
                  }
                }
              }
            }
          );
        }
        this.editedFields.forEach((editedData: any, editedDataIdx: number) => {
          if (editedData.parentSlug === this.removeMultiForm.formName) {
            const removeEditedUnsavedIdx = editedData.updatedFields.findIndex(
              (updateData: any) => {
                return (
                  updateData.data.indexPosition === this.removeMultiForm.index
                );
              }
            );
            if (removeEditedUnsavedIdx > -1) {
              this.changesCount =
                this.changesCount -
                editedData.updatedFields[removeEditedUnsavedIdx].data
                  .trainingData.length;
              editedData.updatedFields.splice(removeEditedUnsavedIdx, 1);
              if (!editedData.updatedFields.length) {
                this.editedFields.splice(editedDataIdx, 1);
              }
            }
          }
        });
        const removedFieldExists = this.previewPayload["History"].findIndex(
          (historyData: any, historyIdx: number) => {
            return historyData["removed" + formLabel + "Idx"];
          }
        );
        if (removedFieldExists < 0) {
          this.previewPayload["History"].push({
            ["removed" + formLabel + "Idx"]: [
              {
                index: this.removeMultiForm.index,
                editorDetails: {
                  name: this.userDetails.displayName,
                  email: this.userDetails.email,
                  userId: this.userDetails.userId,
                },
                isSaved: false,
              },
            ],
          });
        } else {
          this.previewPayload["History"][removedFieldExists][
            "removed" + formLabel + "Idx"
          ].push({
            index: this.removeMultiForm.index,
            editorDetails: {
              name: this.userDetails.displayName,
              email: this.userDetails.email,
              userId: this.userDetails.userId,
            },
            isSaved: false,
          });
        }
      } else {
        this.previewPayload["History"] = [
          {
            ["removed" + formLabel + "Idx"]: [
              {
                index: this.removeMultiForm.index,
                editorDetails: {
                  name: this.userDetails.displayName,
                  email: this.userDetails.email,
                  userId: this.userDetails.userId,
                },
                isSaved: false,
              },
            ],
          },
        ];
      }
    } else {
      if (
        this.previewPayload["History"] &&
        this.previewPayload["History"].length
      ) {
        this.previewPayload["History"].forEach(
          (histroyData: any, historyIdx: number) => {
            const newIdx = histroyData["new" + formLabel + "Idx"].findIndex(
              (newIdx: any) => {
                return newIdx.index === this.removeMultiForm.index;
              }
            );
            histroyData["new" + formLabel + "Idx"].splice(newIdx, 1);
            this.editedFields.forEach(
              (editedData: any, editedDataIdx: number) => {
                if (editedData.parentSlug === this.removeMultiForm.formName) {
                  const removeEditedUnsavedIdx =
                    editedData.updatedFields.findIndex((updateData: any) => {
                      return (
                        updateData.data.trainingName ===
                        this.removeMultiForm.data.trainingName
                      );
                    });
                  if (removeEditedUnsavedIdx > -1) {
                    this.changesCount--;
                    editedData.updatedFields.splice(removeEditedUnsavedIdx, 1);
                    if (!editedData.updatedFields.length) {
                      this.editedFields.splice(editedDataIdx, 1);
                    }
                  }
                }
              }
            );
            if (!histroyData["new" + formLabel + "Idx"].length) {
              delete histroyData["new" + formLabel + "Idx"];
              if (!Object.keys(histroyData).length) {
                this.previewPayload["History"].splice(historyIdx, 1);
                if (!this.previewPayload["History"].length) {
                  delete this.previewPayload["History"];
                }
              }
            }
          }
        );
      }
    }
    this.previewPayload["History"] &&
      this.previewPayload["History"].forEach((data: any) => {
        if (
          data["removed" + formLabel + "Idx"] &&
          data["removed" + formLabel + "Idx"].length
        ) {
          this.$emit(
            "removed" + formLabel + "Idx",
            data["removed" + formLabel + "Idx"]
          );
        }
      });
  }

  public closeSidebar() {
    this.editApplication = false;
    this.closeBackdrop();
  }

  public confirmChanges() {
    if (this.editHistoryComments.comment === "") {
      this.isInvalidComment = true;
    } else {
      this.editHistoryComments.dateTime = moment().format();
      this.editHistoryComments.editorDetails = {
        name: this.userDetails.displayName,
        email: this.userDetails.email,
        userId: this.userDetails.userId,
      };
      this.isInvalidComment = false;
    }
    if (!this.isInvalidComment) {
      this.editedFormNames.forEach((formName: string) => {
        if (formName === "renewalTrainingInformation") {
          let formLabel = "";
          formLabel =
            this.currentRoute.split("/")[2] === "renewal" ? "Training" : "";
          this.previewPayload["History"].forEach((editedData: any) => {
            editedData["new" + formLabel + "Idx"] &&
              editedData["new" + formLabel + "Idx"].forEach((newData: any) => {
                if (newData.isSaved === false) {
                  newData.editDataTime = moment().format();
                  delete newData.isSaved;
                }
                delete newData.isNewlyAdded;
                delete newData.mainLabel;
              });
            editedData["removed" + formLabel + "Idx"] &&
              editedData["removed" + formLabel + "Idx"].forEach(
                (removedData: any) => {
                  if (removedData.isSaved === false) {
                    removedData.editDataTime = moment().format();
                    delete removedData.isSaved;
                  }
                  delete editedData.isRemoved;
                  delete editedData.mainLabel;
                }
              );
            if (this.previewPayload[formName].length) {
              for (let i = 0; i < this.previewPayload[formName].length; i++) {
                if (this.previewPayload[formName][i]) {
                  if (this.previewPayload[formName][i].isSaved === false) {
                    delete this.previewPayload[formName][i].isSaved;
                  }
                  delete this.previewPayload[formName][i]?.isRemoved;
                  delete this.previewPayload[formName][i]?.isNewlyAdded;
                  delete this.previewPayload[formName][i]?.fullName;
                }
                for (const prop in this.previewPayload[formName][0]) {
                  if (editedData[prop + "_" + i]) {
                    editedData[prop + "_" + i].forEach((editedData: any) => {
                      if (editedData.isSaved === false) {
                        editedData.editDataTime = moment().format();
                        delete editedData.isSaved;
                      }
                    });
                  }
                }
              }
            }
          });
        }
        let previewDatas;
        if (
          formName === "certificationPartTwoSupervisorInformation" ||
          formName === "certificationPartTwoRefererenceInformation" ||
          formName === "renewalSupervisorInformation"
        ) {
          previewDatas = this.previewPayload[formName][this.editIndex];
        } else {
          previewDatas = this.previewPayload[formName];
        }
        for (const innerProperty in previewDatas) {
          if (innerProperty === "handbook") {
            for (const SOAproperty in this.previewPayload[formName][
              innerProperty
            ]) {
              for (const SOAinnerProperty in this.previewPayload[formName][
                innerProperty
              ][SOAproperty]) {
                this.previewPayload["History"].forEach((editedData: any) => {
                  editedData[SOAinnerProperty] &&
                    editedData[SOAinnerProperty].forEach((fieldData: any) => {
                      if (fieldData.isSaved === false) {
                        fieldData.editDataTime = moment().format();
                        delete fieldData.isSaved;
                      }
                    });
                });
              }
            }
          } else if (innerProperty === "experienceInformation") {
            for (const experienceProperty in this.previewPayload[formName][
              innerProperty
            ]) {
              for (const experienceInnerProperty in this.previewPayload[
                formName
              ][innerProperty][experienceProperty]) {
                this.previewPayload["History"].forEach((editedData: any) => {
                  editedData[
                    experienceProperty + "_" + experienceInnerProperty
                  ] &&
                    editedData[
                      experienceProperty + "_" + experienceInnerProperty
                    ].forEach((fieldData: any) => {
                      if (fieldData.isSaved === false) {
                        fieldData.editDataTime = moment().format();
                        delete fieldData.isSaved;
                      }
                    });
                });
              }
            }
          }
          this.previewPayload["History"].forEach((editedData: any) => {
            editedData[innerProperty] &&
              editedData[innerProperty].forEach((fieldData: any) => {
                if (fieldData.isSaved === false) {
                  fieldData.editDataTime = moment().format();
                  delete fieldData.isSaved;
                }
              });
          });
        }
      });
      if (
        this.previewPayload["editedComments"] &&
        this.previewPayload["editedComments"].length
      ) {
        this.previewPayload["editedComments"].push(this.editHistoryComments);
      } else {
        this.previewPayload["editedComments"] = [this.editHistoryComments];
      }
      this.$emit("isConfirmChanges", true);
      this.editedFields = [];
      this.changesCount = 0;
      this.$emit("editedData", this.previewPayload);
    }
  }
  public closeBackdrop() {
    this.isSidebarVisible = false;
    document.body.classList.remove("hide-scroll");
  }
}
